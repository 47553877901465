import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Button, Checkbox, message, Typography } from 'antd';
import { UserOutlined, LockOutlined, GiftOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';

const { Title, Text } = Typography;

// 使用 styled-components 替代外部 CSS
const AuthContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 64px);
  padding: 20px;
  background-color: #fbfbff;
`;

const AuthCard = styled.div`
  width: 100%;
  max-width: 400px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 30px;
`;

const AuthHeader = styled.div`
  text-align: center;
  margin-bottom: 30px;
`;

const AuthFooter = styled.div`
  margin-top: 30px;
  text-align: center;
`;

const LoginPage = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onFinish = (values) => {
    setLoading(true);
    
    // 检查邀请码是否正确 - 无论输入什么都显示不正确
    setTimeout(() => {
      // 显示邀请码不正确的消息
      message.error('邀请码填写不正确');
      setLoading(false);
    }, 1500);
    
    // 以下代码不会执行，因为我们总是显示邀请码不正确
    // 保留这些代码是为了将来可能的修改
    /*
    // 模拟成功登录
    message.success('登录成功');
    localStorage.setItem('user', JSON.stringify({ username: values.username }));
    navigate('/dashboard');
    */
  };

  const handleLoginSuccess = () => {
    const redirectUrl = localStorage.getItem('redirectUrl');
    if (redirectUrl) {
      localStorage.removeItem('redirectUrl'); // 清除保存的 URL
      navigate(redirectUrl);
    } else {
      navigate('/'); // 默认跳转到首页
    }
  };

  return (
    <AuthContainer>
      <AuthCard>
        <AuthHeader>
          <Title level={2} style={{ marginBottom: '8px' }}>登录到 Dyoaa</Title>
          <Text type="secondary" style={{ display: 'block', marginBottom: '20px' }}>
            仅限邀请用户登录使用
          </Text>
        </AuthHeader>
        
        <Form
          name="login_form"
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          size="large"
        >
          <Form.Item
            name="username"
            rules={[{ required: true, message: '请输入您的用户名!' }]}
          >
            <Input 
              prefix={<UserOutlined className="site-form-item-icon" />} 
              placeholder="用户名" 
            />
          </Form.Item>
          
          <Form.Item
            name="password"
            rules={[{ required: true, message: '请输入您的密码!' }]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="密码"
            />
          </Form.Item>
          
          <Form.Item
            name="inviteCode"
            rules={[{ required: true, message: '请输入邀请码!' }]}
          >
            <Input
              prefix={<GiftOutlined className="site-form-item-icon" />}
              placeholder="邀请码"
            />
          </Form.Item>
          
          <Form.Item>
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>记住我</Checkbox>
            </Form.Item>
          </Form.Item>

          <Form.Item>
            <Button 
              type="primary" 
              htmlType="submit" 
              className="login-form-button"
              loading={loading}
              block
            >
              登录
            </Button>
          </Form.Item>
        </Form>
        
        <AuthFooter>
          <Text type="secondary" style={{ fontSize: '12px' }}>
            如需获取邀请码，请联系管理员
          </Text>
        </AuthFooter>
      </AuthCard>
    </AuthContainer>
  );
};

export default LoginPage; 