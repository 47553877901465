import { createSlice } from '@reduxjs/toolkit';

const agentSlice = createSlice({
  name: 'agents',
  initialState: {
    list: [],
    selected: null,
    loading: false,
    error: null
  },
  reducers: {
    setAgents: (state, action) => {
      state.list = action.payload;
    },
    setSelectedAgent: (state, action) => {
      state.selected = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    }
  }
});

export const { setAgents, setSelectedAgent, setLoading, setError } = agentSlice.actions;
export default agentSlice.reducer; 