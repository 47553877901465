import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Input, Select, Tag, Button, Avatar, Divider, Modal, message, Rate, Badge, Switch, Radio } from 'antd';
import { SearchOutlined, FilterOutlined, BellOutlined, RobotOutlined, LineChartOutlined, 
  InfoCircleOutlined, StarFilled, TeamOutlined, CheckCircleOutlined, CloseCircleOutlined, SafetyOutlined, AlertOutlined, FireOutlined, ClockCircleOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { theme } from '../styles/theme';
import Footer from '../components/Layout/Footer';

const { Option } = Select;
const { Group: RadioGroup } = Radio;

// 样式组件
const PageContainer = styled.div`
  padding: 24px;
  max-width: 1200px;
  margin: 0 auto;
`;

const PageHeader = styled.div`
  margin-bottom: 24px;
`;

const PageTitle = styled.h1`
  font-size: 28px;
  margin-bottom: 8px;
  color: ${theme.colors.text.primary};
`;

const PageDescription = styled.p`
  color: ${theme.colors.text.secondary};
  font-size: 16px;
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  flex-wrap: wrap;
  gap: 16px;
`;

const SearchInput = styled(Input)`
  max-width: 300px;
`;

const FilterGroup = styled.div`
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
`;

const SignalCard = styled(Card)`
  height: 100%;
  transition: all 0.3s;
  cursor: pointer;
  border-radius: 8px;
  overflow: hidden;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  }
`;

const CardContent = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const SignalHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const SignalIcon = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 12px;
  background-color: ${props => props.bgcolor || theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  font-size: 24px;
  color: white;
`;

const SignalInfo = styled.div`
  flex: 1;
`;

const SignalName = styled.h3`
  margin: 0 0 4px 0;
  font-size: 16px;
  font-weight: 500;
  color: ${theme.colors.text.primary};
`;

const SignalType = styled.div`
  font-size: 14px;
  color: ${theme.colors.text.secondary};
  display: flex;
  align-items: center;
`;

const SignalTags = styled.div`
  margin: 12px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const SignalDescription = styled.p`
  color: ${theme.colors.text.secondary};
  margin: 12px 0;
  font-size: 14px;
  line-height: 1.6;
  flex: 1;
  height: 66px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const StatsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 12px 0;
  padding: 8px 0;
  border-top: 1px solid ${theme.colors.border};
`;

const StatItem = styled.div`
  display: flex;
  align-items: center;
  color: ${theme.colors.text.secondary};
  font-size: 14px;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
  padding-top: 12px;
  border-top: 1px solid ${theme.colors.border};
`;

const ModalContent = styled.div`
  padding: 20px 0;
`;

const ModalHeader = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const ModalIcon = styled(Avatar)`
  width: 60px;
  height: 60px;
  border-radius: 12px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  background-color: ${props => props.bgcolor || theme.colors.primary};
`;

const ModalTitle = styled.h2`
  margin: 0 0 8px 0;
  font-size: 22px;
`;

const ModalDescription = styled.p`
  color: ${theme.colors.text.secondary};
  margin: 16px 0;
  line-height: 1.6;
`;

const FeatureList = styled.ul`
  padding-left: 20px;
  margin-bottom: 24px;
`;

const FeatureItem = styled.li`
  margin-bottom: 8px;
  color: ${theme.colors.text.secondary};
`;

const FormSection = styled.div`
  margin: 24px 0;
`;

const FormTitle = styled.h3`
  margin-bottom: 16px;
  font-size: 18px;
`;

const AuthorizedBadge = styled(Badge)`
  margin-left: 8px;
`;

// 模拟信号数据
const signals = [
  {
    id: 1,
    name: "KOL推文监控",
    icon: <BellOutlined />,
    iconColor: "#1DA1F2",
    type: "social",
    tags: ["Twitter", "KOL", "新币"],
    description: "监控指定KOL的推文，当发现新币相关内容时触发交易信号。",
    scenarios: [
      "监控指定KOL账号的推文",
      "识别推文中的代币信息",
      "分析推文情绪和影响力",
      "生成交易信号和建议"
    ],
    rating: 4.8,
    usageCount: 12568,
    successRate: "89%",
    accuracy: "94%",
    updateFrequency: "实时"
  },
  {
    id: 2,
    name: "聪明钱钱包跟踪",
    icon: <LineChartOutlined />,
    iconColor: "#722ED1",
    type: "wallet",
    tags: ["钱包", "大户", "跟单"],
    description: "跟踪知名投资者和鲸鱼钱包的交易活动，当发生大额交易时触发信号。",
    scenarios: [
      "监控指定钱包地址的交易",
      "分析交易金额和方向",
      "评估交易影响和市场反应",
      "生成跟单信号"
    ],
    rating: 4.9,
    usageCount: 15678,
    successRate: "92%",
    accuracy: "96%",
    updateFrequency: "实时"
  },
  {
    id: 3,
    name: "交易所上币监控",
    icon: <RobotOutlined />,
    iconColor: "#13C2C2",
    type: "exchange",
    tags: ["交易所", "新币", "上线"],
    description: "监控主流交易所的上币公告，当有新币上线时第一时间触发交易信号。",
    scenarios: [
      "监控交易所公告和社交媒体",
      "识别新币上线信息",
      "评估新币潜力和风险",
      "生成交易时机和建议"
    ],
    rating: 4.7,
    usageCount: 9876,
    successRate: "92%",
    accuracy: "91%",
    updateFrequency: "5分钟"
  },
  {
    id: 4,
    name: "BTC黑天鹅监控",
    icon: <LineChartOutlined />,
    iconColor: "#F5222D",
    type: "market",
    tags: ["BTC", "暴跌", "抄底"],
    description: "监控BTC价格波动，当日内跌幅超过20%时触发抄底信号。",
    scenarios: [
      "实时监控BTC价格走势",
      "分析暴跌原因和市场情绪",
      "评估抄底时机和风险",
      "生成分批买入建议"
    ],
    rating: 4.6,
    usageCount: 8543,
    successRate: "78%",
    accuracy: "94%",
    updateFrequency: "实时"
  },
  {
    id: 8,
    type: "news",
    name: "突发新闻监控",
    description: "实时监控加密货币相关的重大新闻事件，第一时间捕捉市场机会",
    icon: <AlertOutlined style={{ color: "#FF4D4F" }} />,
    tags: ["新闻监控", "实时更新", "重大事件"],
    status: "active",
    accuracy: "94%",
    updateFrequency: "实时",
    lastUpdate: "2分钟前",
    scenarios: [
      "全球新闻源监控",
      "新闻情绪分析",
      "事件影响评估",
      "市场反应预测",
      "多语言新闻覆盖"
    ],
    rating: 4.8,
    usageCount: 12345,
    successRate: "88%"
  },
  {
    id: 9,
    type: "sentiment",
    name: "热点情绪监控",
    description: "追踪社交媒体和市场情绪变化，识别潜在的市场热点",
    icon: <FireOutlined style={{ color: "#FA541C" }} />,
    tags: ["情绪分析", "热点追踪", "趋势预测"],
    status: "active",
    accuracy: "91%",
    updateFrequency: "5分钟",
    lastUpdate: "3分钟前",
    scenarios: [
      "社交媒体情绪分析",
      "热点话题追踪",
      "情绪指标监控",
      "趋势形成预警",
      "市场情绪仪表盘"
    ],
    rating: 4.7,
    usageCount: 9876,
    successRate: "85%"
  }
];

const SignalsPage = () => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const [selectedType, setSelectedType] = useState('all');
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedSignal, setSelectedSignal] = useState(null);
  const [authorizedSignals, setAuthorizedSignals] = useState([]);
  
  // 过滤信号
  const filteredSignals = signals.filter(signal => {
    const matchesSearch = signal.name.toLowerCase().includes(searchText.toLowerCase()) ||
                         signal.description.toLowerCase().includes(searchText.toLowerCase());
    const matchesType = selectedType === 'all' || signal.type === selectedType;
    return matchesSearch && matchesType;
  });
  
  // 处理搜索输入
  const handleSearch = e => {
    setSearchText(e.target.value);
  };
  
  // 处理类型选择
  const handleTypeChange = value => {
    setSelectedType(value);
  };
  
  // 处理信号点击
  const handleSignalClick = id => {
    const signal = signals.find(s => s.id === id);
    if (signal) {
      setSelectedSignal(signal);
      setModalVisible(true);
    }
  };
  
  // 处理详情按钮点击
  const handleDetailClick = (e, id) => {
    e.stopPropagation();
    handleSignalClick(id);
  };
  
  // 处理授权使用
  const handleAuthorize = (signalId, mode, assistantId) => {
    // 检查用户是否登录
    const isLoggedIn = false; // 实际应用中应该从认证状态或 localStorage 中获取
    
    if (!isLoggedIn) {
      message.warning('请先登录后再授权使用 AI 信号');
      // 可以选择导航到登录页面
      // navigate('/login');
      return;
    }
    
    // 如果已登录，则处理授权逻辑
    message.success('授权成功！');
    setModalVisible(false);
    
    // 添加到已授权信号列表
    if (!authorizedSignals.includes(signalId)) {
      setAuthorizedSignals([...authorizedSignals, signalId]);
    }
  };
  
  // 处理取消授权
  const handleCancelAuthorize = (e, id) => {
    e.stopPropagation();
    setAuthorizedSignals(authorizedSignals.filter(signalId => signalId !== id));
    message.success('已取消授权');
  };
  
  // 模拟 AI 助理数据（原来是 AI 交易员数据）
  const assistants = [
    {
      id: 1,
      name: "市场分析助理",
      avatar: <LineChartOutlined />,
      avatarColor: "#13C2C2",
    },
    {
      id: 2,
      name: "交易信号助理",
      avatar: <RobotOutlined />,
      avatarColor: "#52C41A",
    },
    {
      id: 3,
      name: "风险管理助理",
      avatar: <SafetyOutlined />,
      avatarColor: "#FA8C16",
    }
  ];
  
  return (
    <>
      <PageContainer>
        <PageHeader>
          <PageTitle>AI信号</PageTitle>
          <PageDescription>
            智能监控市场动态，捕捉交易机会，辅助或自动执行交易决策
          </PageDescription>
        </PageHeader>
        
        <FilterContainer>
          <SearchInput 
            placeholder="搜索AI信号" 
            prefix={<SearchOutlined />} 
            onChange={handleSearch}
            value={searchText}
          />
          
          <FilterGroup>
            <Select 
              defaultValue="all" 
              style={{ width: 120 }} 
              onChange={handleTypeChange}
              value={selectedType}
            >
              <Option value="all">全部类型</Option>
              <Option value="kol">KOL监控</Option>
              <Option value="wallet">钱包跟踪</Option>
              <Option value="exchange">交易所</Option>
              <Option value="market">市场监控</Option>
              <Option value="news">突发新闻</Option>
              <Option value="sentiment">热点情绪</Option>
            </Select>
          </FilterGroup>
        </FilterContainer>
        
        <Row gutter={[24, 24]}>
          {filteredSignals.map(signal => (
            <Col xs={24} sm={12} md={8} key={signal.id}>
              <SignalCard onClick={() => handleSignalClick(signal.id)}>
                <CardContent>
                  <SignalHeader>
                    <SignalIcon bgcolor={signal.iconColor}>
                      {signal.icon}
                    </SignalIcon>
                    <SignalInfo>
                      <SignalName>{signal.name}</SignalName>
                      <SignalType>
                        <TeamOutlined style={{ marginRight: 4 }} />
                        {signal.type === 'market' ? '市场分析' : 
                         signal.type === 'trading' ? '交易信号' :
                         signal.type === 'risk' ? '风险监控' :
                         signal.type === 'sentiment' ? '情绪分析' : '其他'}
                      </SignalType>
                    </SignalInfo>
                  </SignalHeader>
                  
                  <SignalTags>
                    {signal.tags.map((tag, index) => (
                      <Tag key={index} color={signal.iconColor || theme.colors.primary}>{tag}</Tag>
                    ))}
                  </SignalTags>
                  
                  <SignalDescription>{signal.description}</SignalDescription>
                  
                  <StatsContainer>
                    <StatItem>
                      <CheckCircleOutlined style={{ color: '#52c41a', marginRight: 4 }} />
                      准确率 {signal.accuracy}
                    </StatItem>
                    <StatItem>
                      <ClockCircleOutlined style={{ marginRight: 4 }} />
                      {signal.updateFrequency}更新
                    </StatItem>
                  </StatsContainer>
                  
                  <ButtonGroup>
                    {authorizedSignals.includes(signal.id) ? (
                      <Button 
                        danger
                        icon={<CloseCircleOutlined />}
                        onClick={(e) => handleCancelAuthorize(e, signal.id)}
                      >
                        取消授权
                      </Button>
                    ) : (
                      <Button 
                        type="primary"
                        icon={<InfoCircleOutlined />}
                        onClick={(e) => handleDetailClick(e, signal.id)}
                      >
                        查看详情
                      </Button>
                    )}
                  </ButtonGroup>
                </CardContent>
              </SignalCard>
            </Col>
          ))}
        </Row>
        
        <div className="view-more-container" style={{ textAlign: 'center', marginTop: '20px' }}>
          <Button 
            type="primary" 
            onClick={() => {
              const token = localStorage.getItem('token');
              if (!token) {
                Modal.confirm({
                  title: '需要登录',
                  content: '请先登录后再查看完整列表',
                  okText: '去登录',
                  cancelText: '取消',
                  onOk: () => {
                    navigate('/login');
                  }
                });
                return;
              }
              // TODO: 实现查看更多功能
              message.info('更多 AI 信号正在加载中...');
            }}
          >
            查看更多 AI 信号
          </Button>
        </div>
        
        {/* 详情弹窗 */}
        {selectedSignal && (
          <Modal
            visible={modalVisible}
            title={null}
            footer={null}
            onCancel={() => setModalVisible(false)}
            width={600}
            bodyStyle={{ padding: '24px' }}
          >
            <ModalContent>
              <ModalHeader>
                <ModalIcon bgcolor={selectedSignal.iconColor}>
                  {selectedSignal.icon}
                </ModalIcon>
                <div>
                  <ModalTitle>{selectedSignal.name}</ModalTitle>
                  <div style={{ display: 'flex', alignItems: 'center', marginTop: 8 }}>
                    <Rate disabled defaultValue={selectedSignal.rating ? Math.round(selectedSignal.rating) : 0} style={{ fontSize: 16 }} />
                    <span style={{ marginLeft: 8, color: theme.colors.text.secondary }}>
                      {selectedSignal.rating ? selectedSignal.rating.toFixed(1) : '0.0'}分 
                      ({selectedSignal.usageCount ? selectedSignal.usageCount.toLocaleString() : '0'}次使用)
                    </span>
                  </div>
                </div>
              </ModalHeader>
              
              <ModalDescription>
                {selectedSignal.description}
              </ModalDescription>
              
              <Divider />
              
              <h3>应用场景</h3>
              <FeatureList>
                {selectedSignal.scenarios.map((scenario, index) => (
                  <FeatureItem key={index}>{scenario}</FeatureItem>
                ))}
              </FeatureList>
              
              <Divider />
              
              {!authorizedSignals.includes(selectedSignal.id) && (
                <FormSection>
                  <FormTitle>授权设置</FormTitle>
                  
                  <div style={{ marginBottom: 16 }}>
                    <div style={{ marginBottom: 8 }}>交易模式</div>
                    <RadioGroup defaultValue="assist">
                      <Radio value="assist">辅助交易 (仅发送信号通知，需手动确认)</Radio>
                      <Radio value="auto">自主交易 (AI助理自动执行交易)</Radio>
                    </RadioGroup>
                  </div>
                  
                  <div style={{ marginBottom: 24 }}>
                    <div style={{ marginBottom: 8 }}>选择AI助理</div>
                    <Select style={{ width: '100%' }} placeholder="选择要授权的AI助理">
                      {assistants.map(assistant => (
                        <Option key={assistant.id} value={assistant.id}>{assistant.name}</Option>
                      ))}
                    </Select>
                  </div>
                  
                  <Button 
                    type="primary" 
                    size="large" 
                    block
                    onClick={() => handleAuthorize(selectedSignal.id, 'assist', 1)}
                  >
                    确认授权使用
                  </Button>
                </FormSection>
              )}
              
              {authorizedSignals.includes(selectedSignal.id) && (
                <Button 
                  danger
                  size="large" 
                  block
                  onClick={(e) => {
                    handleCancelAuthorize(e, selectedSignal.id);
                    setModalVisible(false);
                  }}
                >
                  取消授权
                </Button>
              )}
            </ModalContent>
          </Modal>
        )}
      </PageContainer>
      <Footer />
    </>
  );
};

export default SignalsPage; 