import React from 'react';
import styled from '@emotion/styled';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import { theme } from '../../styles/theme';

const FooterContainer = styled.footer`
  background-color: #0d1b42;
  padding: 60px 0 30px;
  margin-top: 60px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  color: #fff;
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

const FooterLogo = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 16px;
`;

const FooterLogoText = styled.span`
  font-size: 16px;
  color: rgba(255, 255, 255, 0.7);
  display: block;
  margin-top: 8px;
`;

const FooterTitle = styled.h3`
  font-size: 18px;
  margin-bottom: 16px;
  color: #fff;
`;

const FooterList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const FooterListItem = styled.li`
  margin-bottom: 10px;
  color: rgba(255, 255, 255, 0.8);
  
  a {
    color: rgba(255, 255, 255, 0.9);
    transition: color 0.3s;
    
    &:hover {
      color: #fff;
    }
  }
`;

const SocialIcons = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 16px;
`;

const SocialIcon = styled.a`
  color: rgba(255, 255, 255, 0.9);
  font-size: 16px;
  transition: color 0.3s;
  
  &:hover {
    color: #fff;
  }
`;

const FooterBottom = styled.div`
  text-align: center;
  margin-top: 40px;
  padding-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.5);
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <Row gutter={[40, 24]}>
          <Col xs={24} sm={12} md={8}>
            <FooterLogo>
              Dyoaa<span className="logo-chinese">黛奥</span>
              <FooterLogoText>AI驱动的交易平台</FooterLogoText>
            </FooterLogo>
            <p style={{ color: 'rgba(255, 255, 255, 0.85)' }}>
              Dyoaa黛奥致力于为用户提供智能、高效的交易体验，
              通过AI技术赋能交易决策，让投资更简单、更智能。
            </p>
          </Col>
          
          <Col xs={24} sm={12} md={8}>
            <FooterTitle>快速链接</FooterTitle>
            <FooterList>
              <FooterListItem>
                <Link to="/">首页</Link>
              </FooterListItem>
              <FooterListItem>
                <Link to="/market">AI交易员</Link>
              </FooterListItem>
              <FooterListItem>
                <Link to="/advisor">AI助理</Link>
              </FooterListItem>
              <FooterListItem>
                <Link to="/signals">AI信号</Link>
              </FooterListItem>
              <FooterListItem>
                <Link to="/tools">工具集</Link>
              </FooterListItem>
            </FooterList>
          </Col>
          
          <Col xs={24} sm={12} md={8}>
            <FooterTitle>联系我们</FooterTitle>
            <FooterList>
              <FooterListItem>
                <a href="mailto:support@dyoaa.com">support@dyoaa.com</a>
              </FooterListItem>
              {/* <FooterListItem>
                <a href="https://t.me/+3EJUo_SFvLVjNTg9">Telegram 社区</a>
              </FooterListItem> */}
            </FooterList>
            
            <FooterTitle style={{ marginTop: 24 }}>关注我们</FooterTitle>
            <SocialIcons>
              {/* <SocialIcon href="https://t.me/+3EJUo_SFvLVjNTg9" target="_blank">Telegram</SocialIcon> */}
              <SocialIcon href="https://x.com/dyoaa_lee" target="_blank">Twitter</SocialIcon>
            </SocialIcons>
          </Col>
        </Row>
        
        <FooterBottom>
          <p>&copy; 2023-2025 Dyoaa黛奥. 保留所有权利.</p>
        </FooterBottom>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;