import React from 'react';
import { ConfigProvider, theme as antTheme } from 'antd';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { TaskProvider } from './contexts/TaskContext';
import './App.css';
import './styles/navbar.css';
import './styles/market.css';
import HomePage from './pages/HomePage';
import AppLayout from './components/Layout/AppLayout';
import { GlobalStyles } from './styles/GlobalStyles';
import { AuthProvider } from './contexts/AuthContext';
import ErrorBoundary from './components/common/ErrorBoundary';
import Header from './components/Layout/Navbar';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import AdvisorPage from './pages/AdvisorPage';
import AdvisorChatPage from './pages/AdvisorChatPage';
import AIDecisionPage from './pages/AIDecisionPage.jsx';
import ToolsPage from './pages/ToolsPage';
import SignalsPage from './pages/SignalsPage';

const AgentList = React.lazy(() => import('./components/Agent/AgentList'));
const AgentDetail = React.lazy(() => import('./components/Agent/AgentDetail'));

function App() {
  return (
    <TaskProvider>
      <AuthProvider>
        <ErrorBoundary>
          <ConfigProvider
            theme={{
              algorithm: antTheme.defaultAlgorithm,
              token: {
                colorPrimary: '#315cec',
                colorBgBase: '#fbfbff',
                borderRadius: 8,
              },
            }}
          >
            <GlobalStyles />
            <Router>
              <div className="app">
                <Header />
                <Routes>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/app/*" element={<AppLayout />} />
                  <Route path="/market" element={
                    <React.Suspense fallback={<div>Loading...</div>}>
                      <AgentList />
                    </React.Suspense>
                  } />
                  <Route path="/agent/:id" element={
                    <React.Suspense fallback={<div>Loading...</div>}>
                      <AgentDetail />
                    </React.Suspense>
                  } />
                  <Route path="/advisor" element={<AdvisorPage />} />
                  <Route path="/advisor/chat/:id" element={<AdvisorChatPage />} />
                  <Route path="/signals" element={<SignalsPage />} />
                  <Route path="/tools" element={<ToolsPage />} />
                  <Route path="/login" element={<LoginPage />} />
                  <Route path="/register" element={<RegisterPage />} />
                  <Route path="/ai-decision" element={<AIDecisionPage />} />
                </Routes>
              </div>
            </Router>
          </ConfigProvider>
        </ErrorBoundary>
      </AuthProvider>
    </TaskProvider>
  );
}

export default App; 