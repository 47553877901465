import React from 'react';
import { Outlet } from 'react-router-dom';

const AppLayout = () => {
  return (
    <div className="app-layout">
      {/* 移除这里的导航栏，因为我们已经在 App.js 中添加了全局导航栏 */}
      {/* <Header /> */}
      <main>
        <Outlet />
      </main>
    </div>
  );
};

export default AppLayout;