import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import '../../styles/navbar.css';

export const Header = () => {
  return (
    <header className="app-header">
      <div className="container">
        <div className="logo-container">
          <div className="logo">
            <Link to="/">Dyoaa<span className="logo-chinese">黛奥</span></Link>
          </div>
          <div className="network-badge">
            <span className="test-network-label">TEST网络</span>
            <div className="tooltip-container">
              <span className="info-icon">?</span>
              <div className="tooltip">当前网站属于测试网，所有的数据仅供演示参考</div>
            </div>
          </div>
        </div>
        <nav className="main-nav">
          <ul>
            <li><NavLink to="/" end>首页</NavLink></li>
            <li><NavLink to="/market">AI交易员</NavLink></li>
            <li><NavLink to="/advisor">AI助理</NavLink></li>
            <li><NavLink to="/signals">AI信号</NavLink></li>
            <li><NavLink to="/tools">工具集</NavLink></li>
            {/* <li><NavLink to="/ai-decision"> AI辅助决策</NavLink></li> */}
          </ul>
        </nav>
        <div className="auth-buttons">
          <Link to="/login" className="btn btn-primary">登录</Link>
        </div>
      </div>
    </header>
  );
};

export default Header; 