import React, { useState } from 'react';
import { Input, Button, Card, Typography, Space, Alert } from 'antd';
import { SearchOutlined, TwitterOutlined, LinkOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

export default function AIDecisionPage() {
  const [tokenAddress, setTokenAddress] = useState('');
  const [projectName, setProjectName] = useState('');
  const [twitterHandle, setTwitterHandle] = useState('');
  const [loading, setLoading] = useState(false);
  const [analysisResult, setAnalysisResult] = useState(null);
  const [error, setError] = useState('');

  const handleAnalyze = async () => {
    if (!tokenAddress) {
      setError('请输入代币地址');
      return;
    }

    setLoading(true);
    setError('');
    
    try {
      // TODO: 实现 AI 分析逻辑
      // 这里模拟 API 调用
      await new Promise(resolve => setTimeout(resolve, 2000));
      
      // 模拟数据
      setAnalysisResult({
        basicInfo: {
          name: '示例代币',
          symbol: 'TKN',
          totalSupply: '1,000,000,000',
          holders: 1234,
        },
        marketData: {
          price: '$0.1234',
          marketCap: '$123,456,789',
          volume24h: '$1,234,567',
        },
        technicalIndicators: {
          rsi: 65,
          macd: '看涨',
          volume: '正常',
        },
        riskAssessment: {
          riskLevel: 'medium',
          concerns: ['流动性风险', '市场波动性较大'],
        },
      });
    } catch (err) {
      setError('分析过程中出现错误，请稍后重试');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="text-center mb-8">
        <Title level={2}>AI 智能分析</Title>
        <Text className="text-lg text-gray-600">智能分析，明智决策</Text>
      </div>

      <Card className="mb-8">
        <Space direction="vertical" size="large" className="w-full">
          <Input
            size="large"
            placeholder="输入代币合约地址"
            prefix={<LinkOutlined />}
            value={tokenAddress}
            onChange={(e) => setTokenAddress(e.target.value)}
          />
          <Input
            size="large"
            placeholder="项目名称（可选）"
            value={projectName}
            onChange={(e) => setProjectName(e.target.value)}
          />
          <Input
            size="large"
            placeholder="Twitter 账号（可选）"
            prefix={<TwitterOutlined />}
            value={twitterHandle}
            onChange={(e) => setTwitterHandle(e.target.value)}
          />
          <Button
            type="primary"
            size="large"
            icon={<SearchOutlined />}
            loading={loading}
            onClick={handleAnalyze}
            className="w-full"
          >
            开始分析
          </Button>
        </Space>
      </Card>

      {error && (
        <Alert
          message="错误"
          description={error}
          type="error"
          showIcon
          className="mb-8"
        />
      )}

      {analysisResult && (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <Card title="基本信息">
            <Space direction="vertical">
              <Text>项目名称：{analysisResult.basicInfo.name}</Text>
              <Text>代币符号：{analysisResult.basicInfo.symbol}</Text>
              <Text>总供应量：{analysisResult.basicInfo.totalSupply}</Text>
              <Text>持有人数：{analysisResult.basicInfo.holders}</Text>
            </Space>
          </Card>

          <Card title="市场数据">
            <Space direction="vertical">
              <Text>当前价格：{analysisResult.marketData.price}</Text>
              <Text>市值：{analysisResult.marketData.marketCap}</Text>
              <Text>24h 交易量：{analysisResult.marketData.volume24h}</Text>
            </Space>
          </Card>

          <Card title="技术指标">
            <Space direction="vertical">
              <Text>RSI：{analysisResult.technicalIndicators.rsi}</Text>
              <Text>MACD：{analysisResult.technicalIndicators.macd}</Text>
              <Text>成交量：{analysisResult.technicalIndicators.volume}</Text>
            </Space>
          </Card>

          <Card title="风险评估">
            <Space direction="vertical">
              <Text>风险等级：{analysisResult.riskAssessment.riskLevel}</Text>
              <div>
                <Text>主要风险：</Text>
                <ul className="list-disc pl-5">
                  {analysisResult.riskAssessment.concerns.map((concern, index) => (
                    <li key={index}>{concern}</li>
                  ))}
                </ul>
              </div>
            </Space>
          </Card>
        </div>
      )}
    </div>
  );
} 

