import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/homepage.css'; // 确保导入样式
import TechBackground from '../components/common/TechBackground';
import FeatureCard from '../components/home/FeatureCard';
import AiTypingEffect from '../components/home/AiTypingEffect';
import { RobotOutlined, LineChartOutlined, SafetyOutlined, GlobalOutlined, CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { Row, Col, Button, Modal } from 'antd';
import Footer from '../components/Layout/Footer'; // 导入统一的Footer组件

const HomePage = () => {
  const navigate = useNavigate();
  // 定义图标样式
  const checkStyle = { color: '#52c41a', fontSize: '24px', marginRight: '8px' };
  const closeStyle = { color: '#f5222d', fontSize: '24px', marginRight: '8px' };
  
  return (
    <>
      <div className="tech-background">
        <TechBackground />
      </div>
      
      <section className="hero">
        <div className="container">
          <div className="hero-content">
            <h1 className="hero-title">AI驱动的<span className="highlight">智能交易</span>平台</h1>
            <p className="hero-subtitle">让AI为您的交易决策提供支持，实现全天候自动化交易</p>
            <div className="hero-buttons">
              <Link to="/market" className="btn btn-primary btn-large">开始交易</Link>
              <Link to="/advisor" className="btn btn-secondary btn-large">AI助理</Link>
            </div>
          </div>
          <div className="hero-image">
            <img src="/assets/trading-dashboard.svg" alt="AI交易仪表盘" />
            <div className="floating-data floating-data-1">
              <div className="data-icon">📈</div>
              <div className="data-content">
                <div className="data-title">BTC/USDT</div>
                <div className="data-value up">+2.45%</div>
              </div>
            </div>
            <div className="floating-data floating-data-2">
              <div className="data-icon">🤖</div>
              <div className="data-content">
                <div className="data-title">AI信号</div>
                <div className="data-value">买入</div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ai-typing-section">
        <div className="container">
          <AiTypingEffect />
        </div>
      </section>

      <section id="ai-levels" className="ai-levels">
        <div className="container">
          <h2 className="section-title">Dyoaa 对AI Agent 交易的三个等级定义</h2>
          <div className="levels-container">
            <div className="level-card">
              <div className="level-header">
                <div className="level-badge">L1</div>
                <h3 className="level-title">辅助决策</h3>
              </div>
              <div className="level-content">
                <p>AI分析市场数据并提供交易建议，最终决策由您做出</p>
                <ul className="level-features">
                  <li>实时市场分析</li>
                  <li>技术指标解读</li>
                  <li>风险评估</li>
                  <li>交易机会提醒</li>
                </ul>
              </div>
              <div className="level-footer">
                <Link to="/market" className="btn btn-primary">立即体验</Link>
              </div>
            </div>
            
            <div className="level-card">
              <div className="level-header">
                <div className="level-badge">L2</div>
                <h3 className="level-title">辅助交易</h3>
              </div>
              <div className="level-content">
                <p>AI执行您批准的交易策略，需要您确认重要决策</p>
                <ul className="level-features">
                  <li>策略自动执行</li>
                  <li>关键决策确认</li>
                  <li>实时调整建议</li>
                  <li>风险自动控制</li>
                </ul>
              </div>
              <div className="level-footer">
                <Link to="/market" className="btn btn-primary">立即体验</Link>
              </div>
            </div>
            
            <div className="level-card featured">
              <div className="level-header">
                <div className="level-badge">L3</div>
                <h3 className="level-title">自主交易</h3>
              </div>
              <div className="level-content">
                <p>AI全自动执行交易策略，无需人工干预</p>
                <ul className="level-features">
                  <li>全自动交易执行</li>
                  <li>24/7市场监控</li>
                  <li>自适应策略调整</li>
                  <li>全面风险管理</li>
                </ul>
              </div>
              <div className="level-footer">
                <Link to="/market" className="btn btn-primary">立即体验</Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="comparison" className="comparison">
        <div className="container">
          <h2 className="section-title">AI Agent交易与传统交易方式的区别</h2>
          <div className="comparison-content">
            <table className="comparison-table">
              <thead>
                <tr>
                  <th>特点</th>
                  <th>交易所带单</th>
                  <th>链上聪明钱包跟单</th>
                  <th>AI Agent 交易</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>自动化程度</td>
                  <td><CloseCircleFilled style={closeStyle} />人工操作</td>
                  <td><CheckCircleFilled style={checkStyle} />半自动</td>
                  <td><CheckCircleFilled style={checkStyle} />全自动</td>
                </tr>
                <tr>
                  <td>决策方式</td>
                  <td><CloseCircleFilled style={closeStyle} />主观判断</td>
                  <td><CloseCircleFilled style={closeStyle} />跟随他人</td>
                  <td><CheckCircleFilled style={checkStyle} />基于算法和数据分析</td>
                </tr>
                <tr>
                  <td>交易速度</td>
                  <td><CloseCircleFilled style={closeStyle} />人工速度</td>
                  <td><CheckCircleFilled style={checkStyle} />接近实时</td>
                  <td><CheckCircleFilled style={checkStyle} />毫秒级响应</td>
                </tr>
                <tr>
                  <td>风险控制</td>
                  <td><CloseCircleFilled style={closeStyle} />依赖人工经验</td>
                  <td><CloseCircleFilled style={closeStyle} />完全跟随目标</td>
                  <td><CheckCircleFilled style={checkStyle} />预设参数，严格执行</td>
                </tr>
                <tr>
                  <td>费用结构</td>
                  <td><CloseCircleFilled style={closeStyle} />分成制或管理费</td>
                  <td><CheckCircleFilled style={checkStyle} />固定使用费</td>
                  <td><CheckCircleFilled style={checkStyle} />透明固定费率</td>
                </tr>
                <tr>
                  <td>交易透明度</td>
                  <td><CloseCircleFilled style={closeStyle} />策略可能不公开</td>
                  <td><CheckCircleFilled style={checkStyle} />链上可查证</td>
                  <td><CheckCircleFilled style={checkStyle} />逻辑可追溯</td>
                </tr>
                <tr>
                  <td>资金控制</td>
                  <td><CloseCircleFilled style={closeStyle} />可能需转移资金</td>
                  <td><CheckCircleFilled style={checkStyle} />用户控制</td>
                  <td><CheckCircleFilled style={checkStyle} />用户完全控制</td>
                </tr>
                <tr>
                  <td>交易品种</td>
                  <td><CloseCircleFilled style={closeStyle} />通常限特定市场</td>
                  <td><CloseCircleFilled style={closeStyle} />仅限链上资产</td>
                  <td><CheckCircleFilled style={checkStyle} />跨市场多品种</td>
                </tr>
                <tr>
                  <td>策略更新</td>
                  <td><CloseCircleFilled style={closeStyle} />依赖人工调整</td>
                  <td><CloseCircleFilled style={closeStyle} />被动跟随变化</td>
                  <td><CheckCircleFilled style={checkStyle} />持续优化自动更新</td>
                </tr>
                <tr>
                  <td>交易时间</td>
                  <td><CloseCircleFilled style={closeStyle} />受限于工作时间</td>
                  <td><CheckCircleFilled style={checkStyle} />24/7监控</td>
                  <td><CheckCircleFilled style={checkStyle} />24/7全天候</td>
                </tr>
                <tr>
                  <td>情绪管理</td>
                  <td><CloseCircleFilled style={closeStyle} />受人类情绪影响</td>
                  <td><CloseCircleFilled style={closeStyle} />跟随目标情绪</td>
                  <td><CheckCircleFilled style={checkStyle} />无情绪干扰</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>

      <section id="features" className="features">
        <div className="container">
          <h2 className="section-title">AI 交易的核心优势</h2>
          <Row gutter={[24, 24]}>
            <Col xs={24} sm={12} lg={6}>
              <FeatureCard 
                icon={<RobotOutlined />}
                title="智能决策"
                description="基于深度学习和自然语言处理的AI模型，能够分析海量市场数据"
                features={[
                  "实时市场分析",
                  "多维度数据整合",
                  "情绪分析"
                ]}
              />
            </Col>
            <Col xs={24} sm={12} lg={6}>
              <FeatureCard 
                icon={<LineChartOutlined />}
                title="策略优化"
                description="AI持续学习和优化交易策略，适应不断变化的市场环境"
                features={[
                  "自适应策略调整",
                  "回测与模拟交易",
                  "风险管理"
                ]}
              />
            </Col>
            <Col xs={24} sm={12} lg={6}>
              <FeatureCard 
                icon={<SafetyOutlined />}
                title="风险控制"
                description="内置多层风险控制机制，保障资金安全"
                features={[
                  "止损策略",
                  "资金分配优化",
                  "波动性预警"
                ]}
              />
            </Col>
            <Col xs={24} sm={12} lg={6}>
              <FeatureCard 
                icon={<GlobalOutlined />}
                title="全球市场"
                description="支持多个交易所和市场，提供全球化交易视角"
                features={[
                  "多交易所支持",
                  "跨市场套利",
                  "24/7全天候监控"
                ]}
              />
            </Col>
          </Row>
        </div>
      </section>

      <section className="cta">
        <div className="container">
          <div className="cta-content">
            <h2>准备好体验AI驱动的交易了吗？</h2>
            <p>立即注册Dyoaa，开启智能交易之旅</p>
            <Link to="/login" className="btn btn-primary btn-large">开始交易</Link>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default HomePage; 