import React, { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';

const Container = styled.div`
  margin: 40px 0;
  text-align: center;
`;

const TypedText = styled.div`
  font-family: 'Courier New', monospace;
  font-size: 18px;
  color: #315cec;
  background-color: rgba(49, 92, 236, 0.05);
  padding: 20px;
  border-radius: 8px;
  display: inline-block;
  min-width: 500px;
  text-align: left;
  position: relative;
  box-shadow: 0 4px 15px rgba(49, 92, 236, 0.1);
  line-height: 1.5;
  
  &::after {
    content: '|';
    animation: blink 1s infinite;
  }
  
  @keyframes blink {
    0%, 100% { opacity: 1; }
    50% { opacity: 0; }
  }
`;

const AiTypingEffect = () => {
  // 定义短语数组
  const phrases = [
    "AI 分析: BTC/USDT 价格形成上升三角形态，成交量增加，建议在当前价格附近设置买入点，目标价上涨15%，止损设置在支撑位下方。",
    "ETH 技术分析: 突破 200 日均线，RSI 指标显示 65，未超买。预计短期内有 12% 上涨空间，建议分批买入策略。预期年化收益率: 32.5%。",
    "风险评估报告: 市场恐慧指数 42 (中性)，波动率降低 15%，适合增加仓位。建议配置: BTC 40%, ETH 30%, 稳定币 30%。预期月收益: +7.8%。",
    "AI 预测: 根据链上数据和社交情绪分析，BNB 将在近期测试重要阻力位，突破概率 68%，潜在收益 15%。预期风险回报比: 3.2。",
    "投资组合优化: 分析您的交易历史，AI 建议减少高频交易，增加持仓时间至少 15 天。预期可提高年化收益率 8.5%，降低交易成本 12.3%。"
  ];
  
  // 状态
  const [text, setText] = useState('');
  
  // 引用
  const indexRef = useRef(0);
  const charIndexRef = useRef(0);
  
  // 启动打字效果
  useEffect(() => {
    function type() {
      const currentPhrase = phrases[indexRef.current];
      
      if (charIndexRef.current < currentPhrase.length) {
        // 继续打字
        setText(currentPhrase.substring(0, charIndexRef.current + 1));
        charIndexRef.current += 1;
        setTimeout(type, 50);
      } else {
        // 打字完成，等待一段时间后切换到下一个短语
        setTimeout(() => {
          // 重置字符索引
          charIndexRef.current = 0;
          // 更新短语索引
          indexRef.current = (indexRef.current + 1) % phrases.length;
          // 清空文本
          setText('');
          // 开始下一个短语的打字
          setTimeout(type, 100);
        }, 3000);
      }
    }
    
    // 开始第一个短语的打字
    type();
    
    // 清理函数 - 组件卸载时不需要特别处理，因为不再有定时器引用
    return () => {
      // 如果需要，可以在这里添加清理代码
    };
  }, []);
  
  return (
    <Container>
      <TypedText>{text}</TypedText>
    </Container>
  );
};

export default AiTypingEffect; 