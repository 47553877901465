import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, Input, Button, Avatar, List, Typography } from 'antd';
import { SendOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { theme } from '../styles/theme';

const { Text, Title } = Typography;

const PageContainer = styled.div`
  padding: 24px;
  max-width: 1000px;
  margin: 0 auto;
  height: calc(100vh - 120px);
  display: flex;
  flex-direction: column;
`;

const ChatHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

const BackButton = styled(Button)`
  margin-right: 16px;
`;

const ChatCard = styled(Card)`
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
`;

const MessageList = styled(List)`
  flex: 1;
  overflow-y: auto;
  padding: 0 16px;
`;

const MessageItem = styled(List.Item)`
  padding: 12px 0;
`;

const MessageContent = styled.div`
  background-color: ${props => props.isUser ? theme.colors.primary + '15' : '#f5f5f5'};
  padding: 12px 16px;
  border-radius: 8px;
  max-width: 80%;
  margin-left: ${props => props.isUser ? 'auto' : '0'};
  margin-right: ${props => props.isUser ? '0' : 'auto'};
`;

const InputContainer = styled.div`
  display: flex;
  padding: 16px;
  border-top: 1px solid ${theme.colors.border};
`;

const ChatInput = styled(Input)`
  margin-right: 12px;
`;

const AdvisorChatPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState('');
  const [messages, setMessages] = useState([]);
  const [advisor, setAdvisor] = useState(null);
  
  // 模拟辅导员数据
  useEffect(() => {
    // 这里应该从API获取辅导员数据
    const advisorData = {
      1: {
        name: "社交监控助手",
        avatar: "🐦",
        avatarColor: "#1DA1F2",
        greeting: "你好！我是社交监控助手，可以帮你监控Twitter上的KOL动态。请告诉我你想监控哪些账号？"
      },
      2: {
        name: "内容创作专家",
        avatar: "✍️",
        avatarColor: "#34C759",
        greeting: "你好！我是内容创作专家，可以帮你生成高质量的社交媒体内容。你需要什么类型的内容？"
      },
      3: {
        name: "市场分析师",
        avatar: "📊",
        avatarColor: "#5856D6",
        greeting: "你好！我是市场分析师，可以为你提供最新的市场趋势和分析。你想了解哪个市场的情况？"
      },
      4: {
        name: "交易信号专家",
        avatar: "📈",
        avatarColor: "#FF2D55",
        greeting: "你好！我是交易信号专家，可以为你提供交易信号和建议。你想接收哪些资产的信号？"
      },
      5: {
        name: "数据可视化助手",
        avatar: "📉",
        avatarColor: "#FF9500",
        greeting: "你好！我是数据可视化助手，可以帮你创建直观的数据图表。你有什么数据需要可视化？"
      }
    };
    
    const currentAdvisor = advisorData[id];
    if (currentAdvisor) {
      setAdvisor(currentAdvisor);
      // 添加欢迎消息
      setMessages([
        {
          id: 1,
          content: currentAdvisor.greeting,
          isUser: false,
          timestamp: new Date()
        }
      ]);
    }
  }, [id]);
  
  const handleSendMessage = () => {
    if (!inputValue.trim()) return;
    
    // 添加用户消息
    const userMessage = {
      id: messages.length + 1,
      content: inputValue,
      isUser: true,
      timestamp: new Date()
    };
    
    setMessages(prev => [...prev, userMessage]);
    setInputValue('');
    
    // 模拟AI回复
    setTimeout(() => {
      let response;
      
      // 根据不同的辅导员类型，生成不同的回复
      switch (id) {
        case '1': // 社交监控助手
          response = "我已经开始监控这些账号了。我会实时分析他们的推文，并为你提供关键洞察。你还想了解什么特定的信息吗？";
          break;
        case '2': // 内容创作专家
          response = "我已经为你生成了内容草稿。这篇内容针对你的目标受众进行了优化，包含了相关的关键词和话题。你觉得还需要调整哪些部分？";
          break;
        case '3': // 市场分析师
          response = "根据最新的市场数据，我发现几个值得关注的趋势。交易量在过去一周增加了15%，主要由机构投资者驱动。你想了解更详细的分析吗？";
          break;
        case '4': // 交易信号专家
          response = "根据技术分析，当前市场显示买入信号。RSI指标为48，处于中性区域但向上发展。MACD也显示积极的动能。建议考虑在当前价格附近设置买入点。";
          break;
        case '5': // 数据可视化助手
          response = "我已经根据你提供的数据创建了可视化图表。这个图表清晰地展示了数据趋势和模式。你想对图表进行哪些调整？";
          break;
        default:
          response = "我理解你的需求，正在处理中...";
      }
      
      const aiMessage = {
        id: messages.length + 2,
        content: response,
        isUser: false,
        timestamp: new Date()
      };
      
      setMessages(prev => [...prev, aiMessage]);
    }, 1000);
  };
  
  if (!advisor) {
    return <div>加载中...</div>;
  }
  
  return (
    <PageContainer>
      <ChatHeader>
        <BackButton 
          type="text" 
          icon={<ArrowLeftOutlined />} 
          onClick={() => navigate('/advisor')}
        >
          返回
        </BackButton>
        <Avatar 
          style={{ backgroundColor: advisor.avatarColor, marginRight: 12 }}
        >
          {advisor.avatar}
        </Avatar>
        <Title level={4} style={{ margin: 0 }}>{advisor.name}</Title>
      </ChatHeader>
      
      <ChatCard>
        <MessageList
          dataSource={messages}
          renderItem={message => (
            <MessageItem>
              <MessageContent isUser={message.isUser}>
                <Text>{message.content}</Text>
              </MessageContent>
            </MessageItem>
          )}
        />
        
        <InputContainer>
          <ChatInput 
            placeholder="输入消息..." 
            value={inputValue}
            onChange={e => setInputValue(e.target.value)}
            onPressEnter={handleSendMessage}
          />
          <Button 
            type="primary" 
            icon={<SendOutlined />} 
            onClick={handleSendMessage}
          />
        </InputContainer>
      </ChatCard>
    </PageContainer>
  );
};

export default AdvisorChatPage; 