import React, { createContext, useState, useContext } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);

  const loginWithTwitter = async () => {
    setLoading(true);
    try {
      // TODO: 实现 Twitter OAuth
      window.location.href = 'https://twitter.com/i/oauth2/authorize';
    } catch (error) {
      console.error('Twitter login failed:', error);
    } finally {
      setLoading(false);
    }
  };

  const logout = () => {
    setUser(null);
    // TODO: 清除本地存储的认证信息
  };

  return (
    <AuthContext.Provider value={{ user, loading, loginWithTwitter, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext); 