import React from 'react';
import styled from '@emotion/styled';

const Card = styled.div`
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.7));
  backdrop-filter: blur(10px);
  border-radius: 12px;
  padding: 30px;
  box-shadow: 0 10px 30px rgba(49, 92, 236, 0.1);
  border: 1px solid rgba(49, 92, 236, 0.1);
  transition: all 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 35px rgba(49, 92, 236, 0.15);
    border-color: rgba(49, 92, 236, 0.3);
  }
`;

const IconContainer = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: linear-gradient(135deg, #315cec, #4a6eee);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  color: white;
  font-size: 24px;
`;

const Title = styled.h3`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
  color: #333;
`;

const Description = styled.p`
  color: #666;
  line-height: 1.6;
  margin-bottom: 20px;
  flex-grow: 1;
`;

const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const FeatureItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  
  &:before {
    content: "✓";
    color: #315cec;
    font-weight: bold;
    margin-right: 10px;
  }
`;

const FeatureCard = ({ icon, title, description, features }) => {
  return (
    <Card>
      <IconContainer>{icon}</IconContainer>
      <Title>{title}</Title>
      <Description>{description}</Description>
      <FeatureList>
        {features.map((feature, index) => (
          <FeatureItem key={index}>{feature}</FeatureItem>
        ))}
      </FeatureList>
    </Card>
  );
};

export default FeatureCard; 