import React, { useState } from 'react';
import { Row, Col, Card, Input, Select, Tag, Button, Avatar, Divider, Modal, message, Rate } from 'antd';
import { SearchOutlined, FilterOutlined, ToolOutlined, UserOutlined, InfoCircleOutlined, StarFilled, TeamOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { theme } from '../styles/theme';
import Footer from '../components/Layout/Footer';

const { Option } = Select;

// 样式组件
const PageContainer = styled.div`
  padding: 24px;
  max-width: 1200px;
  margin: 0 auto;
`;

const PageHeader = styled.div`
  margin-bottom: 24px;
`;

const PageTitle = styled.h1`
  font-size: 28px;
  margin-bottom: 8px;
  color: ${theme.colors.text.primary};
`;

const PageDescription = styled.p`
  color: ${theme.colors.text.secondary};
  font-size: 16px;
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  flex-wrap: wrap;
  gap: 16px;
`;

const SearchInput = styled(Input)`
  max-width: 300px;
`;

const FilterGroup = styled.div`
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
`;

const ToolCard = styled(Card)`
  height: 100%;
  transition: all 0.3s;
  cursor: pointer;
  border-radius: 8px;
  overflow: hidden;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  }
`;

const ToolLogo = styled(Avatar)`
  width: 60px;
  height: 60px;
  border-radius: 12px;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  background-color: ${props => props.bgcolor || theme.colors.primary};
`;

const ToolHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

const ToolInfo = styled.div`
  flex: 1;
`;

const ToolName = styled.h3`
  margin: 0;
  font-size: 18px;
`;

const ToolCreator = styled.div`
  color: ${theme.colors.text.secondary};
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-top: 4px;
`;

const ToolTags = styled.div`
  margin: 12px 0;
`;

const ToolDescription = styled.p`
  color: ${theme.colors.text.secondary};
  margin-bottom: 16px;
  height: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ModalContent = styled.div`
  padding: 20px 0;
`;

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

const ModalLogo = styled(Avatar)`
  width: 80px;
  height: 80px;
  border-radius: 16px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  background-color: ${props => props.bgcolor || theme.colors.primary};
`;

const ModalTitle = styled.h2`
  margin: 0;
  font-size: 24px;
`;

const ModalCreator = styled.div`
  color: ${theme.colors.text.secondary};
  font-size: 14px;
  margin-top: 8px;
  display: flex;
  align-items: center;
`;

const ModalDescription = styled.p`
  color: ${theme.colors.text.secondary};
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 24px;
`;

const FeatureList = styled.ul`
  padding-left: 20px;
  margin-bottom: 24px;
`;

const FeatureItem = styled.li`
  margin-bottom: 8px;
  color: ${theme.colors.text.secondary};
`;

// 添加评分和使用次数的样式组件
const StatsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  margin-bottom: 8px;
`;

const StatItem = styled.div`
  display: flex;
  align-items: center;
  color: ${theme.colors.text.secondary};
  font-size: 14px;
`;

const ToolsPage = () => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedTool, setSelectedTool] = useState(null);
  
  // 模拟工具数据
  const tools = [
    {
      id: 1,
      name: "数据分析助手",
      logo: "📊",
      logoColor: "#4CAF50",
      creator: "Dyoaa 官方",
      category: "data",
      tags: ["数据分析", "可视化", "报表"],
      description: "强大的数据分析工具，支持多种数据源导入，自动生成图表和报表，帮助您快速理解数据趋势和模式。",
      features: [
        "支持CSV、Excel、JSON等多种格式数据导入",
        "自动生成柱状图、折线图、饼图等多种图表",
        "智能数据清洗和预处理",
        "一键导出分析报告"
      ],
      rating: 4.9,
      usageCount: 25789
    },
    {
      id: 2,
      name: "交易策略生成器",
      logo: "📈",
      logoColor: "#2196F3",
      creator: "量化团队",
      category: "trading",
      tags: ["交易", "策略", "量化"],
      description: "基于历史数据和市场趋势，自动生成交易策略，支持回测和优化，帮助交易者制定更科学的交易计划。",
      features: [
        "多种技术指标组合策略生成",
        "历史数据回测和性能评估",
        "风险控制参数自动优化",
        "策略导出为代码或交易信号"
      ],
      rating: 4.8,
      usageCount: 12568
    },
    {
      id: 3,
      name: "社交媒体监控",
      logo: "🔍",
      logoColor: "#FF5722",
      creator: "社交分析实验室",
      category: "social",
      tags: ["社交媒体", "舆情", "监控"],
      description: "实时监控多个社交媒体平台的关键词和话题，分析情绪和趋势，帮助您把握市场动向和舆论变化。",
      features: [
        "多平台数据采集（Twitter、Reddit、微博等）",
        "关键词和话题追踪",
        "情绪分析和趋势预测",
        "异常波动预警"
      ],
      rating: 4.7,
      usageCount: 8765
    },
    {
      id: 4,
      name: "智能文案生成",
      logo: "✍️",
      logoColor: "#9C27B0",
      creator: "内容创作工作室",
      category: "content",
      tags: ["文案", "创作", "营销"],
      description: "基于AI的文案生成工具，可以根据关键词和主题自动生成各类营销文案、社交媒体帖子和产品描述。",
      features: [
        "多种文案风格和长度选择",
        "SEO优化建议",
        "A/B测试文案生成",
        "一键发布到多个平台"
      ],
      rating: 4.6,
      usageCount: 5432
    },
    {
      id: 5,
      name: "市场情绪指标",
      logo: "🌡️",
      logoColor: "#F44336",
      creator: "情绪分析团队",
      category: "trading",
      tags: ["情绪", "指标", "市场"],
      description: "综合分析多种市场数据和社交媒体信息，生成市场情绪指标，帮助投资者判断市场过热或过冷状态。",
      features: [
        "恐慧指数实时计算",
        "多周期情绪波动分析",
        "与价格相关性研究",
        "极端情绪预警"
      ],
      rating: 4.5,
      usageCount: 3210
    },
    {
      id: 6,
      name: "代码助手",
      logo: "💻",
      logoColor: "#607D8B",
      creator: "开发者工具组",
      category: "development",
      tags: ["编程", "代码", "开发"],
      description: "智能代码补全和生成工具，支持多种编程语言，提供代码优化建议和错误修复，提高开发效率。",
      features: [
        "多语言代码补全和生成",
        "代码重构和优化建议",
        "Bug自动检测和修复",
        "API使用示例生成"
      ],
      rating: 4.4,
      usageCount: 1598
    }
  ];
  
  // 过滤工具
  const filteredTools = tools.filter(tool => {
    const matchesSearch = 
      tool.name.toLowerCase().includes(searchText.toLowerCase()) ||
      tool.description.toLowerCase().includes(searchText.toLowerCase()) ||
      tool.tags.some(tag => tag.toLowerCase().includes(searchText.toLowerCase()));
    
    const matchesCategory = selectedCategory === 'all' || tool.category === selectedCategory;
    
    return matchesSearch && matchesCategory;
  });
  
  // 处理工具卡片点击
  const handleToolClick = (toolId) => {
    const tool = tools.find(t => t.id === toolId);
    setSelectedTool(tool);
    setModalVisible(true);
  };
  
  // 处理详情按钮点击
  const handleDetailClick = (e, toolId) => {
    e.stopPropagation(); // 阻止事件冒泡，避免触发卡片点击
    const tool = tools.find(t => t.id === toolId);
    setSelectedTool(tool);
    setModalVisible(true);
  };
  
  // 处理使用按钮点击
  const handleUseClick = (toolId) => {
    // 检查用户是否登录
    const isLoggedIn = false; // 实际应用中应该从认证状态或 localStorage 中获取
    
    if (!isLoggedIn) {
      message.warning('请先登录后再使用工具');
      // 可以选择导航到登录页面
      // navigate('/login');
      return;
    }
    
    // 如果已登录，则导航到工具使用页面
    navigate(`/tools/use/${toolId}`);
  };

  return (
    <>
      <PageContainer>
        <PageHeader>
          <PageTitle>工具集</PageTitle>
          <PageDescription>
            探索各种强大的工具，帮助您提高工作效率和分析能力
          </PageDescription>
        </PageHeader>
        
        <FilterContainer>
          <SearchInput 
            placeholder="搜索工具..." 
            prefix={<SearchOutlined />} 
            value={searchText}
            onChange={e => setSearchText(e.target.value)}
          />
          
          <FilterGroup>
            <Select 
              defaultValue="all" 
              style={{ width: 150 }} 
              onChange={value => setSelectedCategory(value)}
              prefix={<FilterOutlined />}
            >
              <Option value="all">所有类别</Option>
              <Option value="data">数据分析</Option>
              <Option value="trading">交易工具</Option>
              <Option value="social">社交监控</Option>
              <Option value="content">内容创作</Option>
              <Option value="development">开发工具</Option>
            </Select>
          </FilterGroup>
        </FilterContainer>
        
        <Row gutter={[24, 24]}>
          {filteredTools.map(tool => (
            <Col xs={24} sm={12} md={8} key={tool.id}>
              <ToolCard onClick={() => handleToolClick(tool.id)}>
                <ToolHeader>
                  <ToolLogo bgcolor={tool.logoColor}>
                    {tool.logo}
                  </ToolLogo>
                  <ToolInfo>
                    <ToolName>{tool.name}</ToolName>
                    <ToolCreator>
                      <UserOutlined style={{ marginRight: 4 }} />
                      {tool.creator}
                    </ToolCreator>
                  </ToolInfo>
                </ToolHeader>
                
                <ToolTags>
                  {tool.tags.map((tag, index) => (
                    <Tag key={index} color={theme.colors.primary}>
                      {tag}
                    </Tag>
                  ))}
                </ToolTags>
                
                <ToolDescription>
                  {tool.description}
                </ToolDescription>
                
                {/* 添加评分和使用次数 */}
                <StatsContainer>
                  <StatItem>
                    <StarFilled style={{ color: '#FADB14', marginRight: 4 }} />
                    {tool.rating ? tool.rating.toFixed(1) : '0.0'}分
                  </StatItem>
                  <StatItem>
                    <TeamOutlined style={{ marginRight: 4 }} />
                    {tool.usageCount ? tool.usageCount.toLocaleString() : '0'}次使用
                  </StatItem>
                </StatsContainer>
                
                <Divider style={{ margin: '12px 0' }} />
                
                <ButtonGroup>
                  <Button 
                    type="primary" 
                    icon={<InfoCircleOutlined />}
                    onClick={(e) => handleDetailClick(e, tool.id)}
                  >
                    查看详情
                  </Button>
                </ButtonGroup>
              </ToolCard>
            </Col>
          ))}
        </Row>
        
        <div className="view-more-container" style={{ textAlign: 'center', marginTop: '20px' }}>
          <Button 
            type="primary" 
            onClick={() => {
              const token = localStorage.getItem('token');
              if (!token) {
                Modal.confirm({
                  title: '需要登录',
                  content: '请先登录后再查看完整列表',
                  okText: '去登录',
                  cancelText: '取消',
                  onOk: () => {
                    navigate('/login');
                  }
                });
                return;
              }
              // TODO: 实现查看更多功能
              message.info('更多工具正在加载中...');
            }}
          >
            查看更多工具
          </Button>
        </div>
        
        {/* 详情弹窗 */}
        {selectedTool && (
          <Modal
            visible={modalVisible}
            title={null}
            footer={null}
            onCancel={() => setModalVisible(false)}
            width={600}
            bodyStyle={{ padding: '24px' }}
          >
            <ModalContent>
              <ModalHeader>
                <ModalLogo bgcolor={selectedTool.logoColor}>
                  {selectedTool.logo}
                </ModalLogo>
                <div>
                  <ModalTitle>{selectedTool.name}</ModalTitle>
                  <ModalCreator>
                    <UserOutlined style={{ marginRight: 4 }} />
                    {selectedTool.creator}
                  </ModalCreator>
                  <div style={{ display: 'flex', alignItems: 'center', marginTop: 8 }}>
                    <Rate disabled defaultValue={selectedTool.rating ? Math.round(selectedTool.rating) : 0} style={{ fontSize: 16 }} />
                    <span style={{ marginLeft: 8, color: theme.colors.text.secondary }}>
                      {selectedTool.rating ? selectedTool.rating.toFixed(1) : '0.0'}分 
                      ({selectedTool.usageCount ? selectedTool.usageCount.toLocaleString() : '0'}次使用)
                    </span>
                  </div>
                </div>
              </ModalHeader>
              
              <ModalDescription>
                {selectedTool.description}
              </ModalDescription>
              
              <Divider />
              
              <h3>主要功能</h3>
              <FeatureList>
                {selectedTool.features.map((feature, index) => (
                  <FeatureItem key={index}>{feature}</FeatureItem>
                ))}
              </FeatureList>
              
              <Button 
                type="primary" 
                size="large" 
                block
                onClick={() => {
                  setModalVisible(false);
                  handleUseClick(selectedTool.id);
                }}
              >
                授权使用
              </Button>
            </ModalContent>
          </Modal>
        )}
      </PageContainer>
      <Footer />
    </>
  );
};

export default ToolsPage; 