import React, { createContext, useState, useContext } from 'react';

const TaskContext = createContext();

export const TaskProvider = ({ children }) => {
  const [tasks, setTasks] = useState([]);
  const [currentPlan, setCurrentPlan] = useState(null);

  const addTasks = (newTasks, planTitle) => {
    setTasks(newTasks);
    setCurrentPlan(planTitle);
  };

  return (
    <TaskContext.Provider value={{ tasks, currentPlan, addTasks }}>
      {children}
    </TaskContext.Provider>
  );
};

export const useTaskContext = () => useContext(TaskContext); 