import React from 'react';
import { BarChartOutlined, LineChartOutlined, RobotOutlined } from '@ant-design/icons';

export const kolData = [
  // 链上 alpha
    {
      id: 1,
      name: "憨厚的麦总",
      creator: "@Michael_Liu93",
      avatar: <img src="https://pbs.twimg.com/profile_images/1864081351693279232/4F-wT4gu_normal.jpg" alt="Guilin_Chen_" style={{ objectFit: 'cover', borderRadius: '20%' }} />,
      avatarColor: "#1DA1F2",
      type: "onchain",
      tags: ["链上alpha", "项目分析", "市场洞察"],
      description: "Ex-VC & Banker Turned Degen. See You In The Trenches",
      features: ["链上数据分析", "项目前瞻", "市场趋势分析", "投资机会挖掘"],
      rating: 4.9,
      usageCount: 15678
    },
    {
      id: 2,
      name: "Ed_x 區塊日記",
      creator: "@Ed_x0101",
      avatar: <img src="https://pbs.twimg.com/profile_images/1588828286464888832/RRN2JuTl_normal.jpg" alt="Guilin_Chen_" style={{ width: '2em', height: '2em', objectFit: 'cover', borderRadius: '20%' }} />,
      avatarColor: "#722ED1",
      type: "onchain",
      tags: ["链上alpha", "区块链", "市场分析"],
      description: "Alpha Intern | Building @Bitcoin_Square_ | Team @silverlinelabs | 真正的大师，永远怀着一颗学徒的心，be water",
      features: ["日常链上动态", "项目分析", "投资机会", "风险提示"],
      rating: 4.8,
      usageCount: 12543
    },
    {
      id: 3,
      name: "CryptoD | 1000X GEM",
      creator: "@CryptoDevinL",
      avatar: <img src="https://pbs.twimg.com/profile_images/1865314120993771524/Pln6jtx-_normal.jpg" alt="Guilin_Chen_" style={{ width: '2em', height: '2em', objectFit: 'cover', borderRadius: '20%' }} />,
      avatarColor: "#13C2C2",
      type: "onchain",
      tags: ["链上alpha", "宝石项目", "投资机会"],
      description: "Degen 🧲 | 無小群，無收費群，無 wechat，只有 NFT 群跟大群，小心詐騙私訊",
      features: ["优质项目发掘", "投资机会分析", "风险评估", "收益预测"],
      rating: 4.7,
      usageCount: 13421
    },
    {
      id: 4,
      name: "0xWizard",
      creator: "@0xcryptowizard",
      avatar: <img src="https://pbs.twimg.com/profile_images/1865248625678520325/K8QQsqDl_normal.jpg" alt="Guilin_Chen_" style={{ width: '2em', height: '2em', objectFit: 'cover', borderRadius: '20%' }} />,
      avatarColor: "#1890FF",
      type: "onchain",
      tags: ["链上alpha", "项目分析", "市场洞察"],
      description: "||👁💎 $act || 🌞🌕 || AI meme infra agent || Defi & Meme Degen || NFA 非投资建议 ⚠️没有任何tg群，不要被钓鱼链接诈骗⚠️",
      features: ["项目分析", "市场洞察", "投资建议", "风险管理"],
      rating: 4.8,
      usageCount: 14567
    },
    {
      id: 5,
      name: "大宇",
      creator: "@BTCdayu",
      avatar: <img src="https://pbs.twimg.com/profile_images/1862767252400967680/mjEMe7kp_normal.jpg" alt="Guilin_Chen_" style={{ width: '2em', height: '2em', objectFit: 'cover', borderRadius: '20%' }} />,
      avatarColor: "#F5222D",
      type: "onchain",
      tags: ["链上alpha", "市场分析", "趋势预测"],
      description: "📙暴富宝典 📈省手续注册大所 🐸Meme神器 📍熊市数亿，进击的A10",
      features: ["市场分析", "趋势预测", "投资策略", "风险控制"],
      rating: 4.7,
      usageCount: 13789
    },
    {
      id: 6,
      name: "王小二",
      creator: "@brc20niubi",
      avatar: <img src="https://pbs.twimg.com/profile_images/1815176227201957890/Acfrx_eh_normal.jpg" alt="Guilin_Chen_" style={{ width: '2em', height: '2em', objectFit: 'cover', borderRadius: '20%' }} />,
      avatarColor: "#52C41A",
      type: "onchain",
      tags: ["链上alpha", "BRC20", "市场分析"],
      description: "我就是全宇宙最厉害的区块链天才，我一定一定一定行！",
      features: ["BRC20分析", "市场趋势", "投资机会", "风险提示"],
      rating: 4.8,
      usageCount: 15234
    },
    // 交易大神
    {
      id: 7,
      name: "allincrypto 熬鹰资本",
      creator: "@thankUcrypto",
      avatar: <img src="https://pbs.twimg.com/profile_images/1653241236827033600/2sCvAcOH_normal.jpg" alt="Guilin_Chen_" style={{ width: '2em', height: '2em', objectFit: 'cover', borderRadius: '20%' }} />,
      avatarColor: "#722ED1",
      type: "trading",
      tags: ["交易大神", "投资策略", "市场分析"],
      description: "个人Tg; 熬鹰web3 TG群 TG群看群简介，走熬鹰返佣并入金后请私聊我，我拉你进熬鹰书友会",
      features: ["交易策略", "市场分析", "风险管理", "仓位管理"],
      rating: 4.9,
      usageCount: 18765
    },
    {
      id: 8,
      name: "Dr.Hash Wesley",
      creator: "@CryptoAprentil",
      avatar: <img src="https://pbs.twimg.com/profile_images/1433096687942193153/GZhUhUsI_normal.jpg" alt="Guilin_Chen_" style={{ width: '2em', height: '2em', objectFit: 'cover', borderRadius: '20%' }} />,
      avatarColor: "#F5222D",
      type: "trading",
      tags: ["交易大神", "技术分析", "市场洞察"],
      description: "Marketing Advisor｜#Airdrop｜Share first-line news！ #实话实说爆光内幕！ Crypto入口，OKX就够 。最全交易工具，畅游130+公链，极速交易热门Meme",
      features: ["技术分析", "走势预测", "交易机会", "风险提示"],
      rating: 4.8,
      usageCount: 16543
    },
    {
      id: 9,
      name: "杰尼 kungfu",
      creator: "@kungfu_crypto",
      avatar: <img src="https://pbs.twimg.com/profile_images/1433096687942193153/GZhUhUsI_normal.jpg" alt="Guilin_Chen_" style={{ width: '2em', height: '2em', objectFit: 'cover', borderRadius: '20%' }} />,
      avatarColor: "#1890FF",
      type: "trading",
      tags: ["交易大神", "交易策略", "市场分析"],
      description: "Degen Investment Manager | $HYPE | Hypeliquid since day1 | My Opinions are my own",
      features: ["交易策略", "市场分析", "风险管理", "技术指标"],
      rating: 4.7,
      usageCount: 14321
    },
    // 撸毛大神
    {
      id: 10,
      name: "Aurora.eth",
      creator: "@Aurorawwwwww",
      avatar: <img src="https://pbs.twimg.com/profile_images/1763225572380205056/NZEHx1_9_400x400.jpg" alt="Guilin_Chen_" style={{ width: '2em', height: '2em', objectFit: 'cover', borderRadius: '20%' }} />,
      avatarColor: "#13C2C2",
      type: "airdrop",
      tags: ["撸毛大神", "空投", "项目分析"],
      description: "日更博主，每日撸毛教程分享",
      features: ["空投信息", "项目分析", "操作指南", "风险提示"],
      rating: 4.7,
      usageCount: 14532
    },
    {
      id: 11,
      name: "老陌",
      creator: "@Blockchainrese6",
      avatar: <img src="https://pbs.twimg.com/profile_images/1601958677497249792/MEwmQCWF_normal.jpg" alt="Guilin_Chen_" style={{ width: '2em', height: '2em', objectFit: 'cover', borderRadius: '20%' }} />,
      avatarColor: "#722ED1",
      type: "airdrop",
      tags: ["撸毛大神", "空投", "项目分析"],
      description: "Marketing Advisor｜#Airdrop｜Share first-line news！ #实话实说爆光内幕！ Crypto入口，OKX就够 。最全交易工具，畅游130+公链，极速交易热门Meme",
      features: ["空投情报", "项目评估", "操作策略", "风险提示"],
      rating: 4.8,
      usageCount: 15678
    },
    {
      id: 12,
      name: "加密狗",
      creator: "@JiamigouCn",
      avatar: <img src="https://pbs.twimg.com/profile_images/1734152302087503872/1tGJJFAh_normal.jpg" alt="Guilin_Chen_" style={{ width: '2em', height: '2em', objectFit: 'cover', borderRadius: '20%' }} />,
      avatarColor: "#F5222D",
      type: "airdrop",
      tags: ["撸毛大神", "空投", "项目分析"],
      description: "#Binance 数字货币交易 #OKX Web3入口 #Bybit 为专业交易者而生",
      features: ["项目筛选", "空投攻略", "收益分析", "风险提示"],
      rating: 4.7,
      usageCount: 13456
    },
    // 链上数据
    {
      id: 13,
      name: "杀破狼 WolfyXBT",
      creator: "@Wolfy_XBT",
      avatar: <img src="https://pbs.twimg.com/profile_images/1680938637628944384/2FYk0Ft4_normal.jpg" alt="Guilin_Chen_" style={{ width: '2em', height: '2em', objectFit: 'cover', borderRadius: '20%' }} />,
      avatarColor: "#1DA1F2",
      type: "data",
      tags: ["链上数据", "市场分析", "趋势预测"],
      description: "数据分析｜信息整理｜全职韭菜｜兼职傻逼｜买 Memecoin 推荐使用 GMGN",
      features: ["数据分析", "趋势预测", "机会挖掘", "风险预警"],
      rating: 4.9,
      usageCount: 17654
    },
    {
      id: 14,
      name: "比特欧 Elvin",
      creator: "@DRbitcoin36",
      avatar: <img src="https://pbs.twimg.com/profile_images/1871549554279194624/SLDJsDr2_normal.jpg" alt="Guilin_Chen_" style={{ width: '2em', height: '2em', objectFit: 'cover', borderRadius: '20%' }} />,
      avatarColor: "#52C41A",
      type: "data",
      tags: ["链上数据", "比特币分析", "市场研究"],
      description: "数据分析 + 数据可视化博主 | 币安广场创作者｜不是矩阵号｜区块链打工人 + 00 后 | 推特当作自己的炒币日记",
      features: ["比特币分析", "市场研究", "数据解读", "趋势预测"],
      rating: 4.8,
      usageCount: 15432
    },
    {
      id: 15,
      name: "Murphy",
      creator: "@Murphychen888",
      avatar: <img src="https://pbs.twimg.com/profile_images/1886766041419931648/5seFXKGC_normal.jpg" alt="Guilin_Chen_" style={{ width: '2em', height: '2em', objectFit: 'cover', borderRadius: '20%' }} />,
      avatarColor: "#722ED1",
      type: "data",
      tags: ["链上数据", "市场分析", "投资策略"],
      description: "17年老韭菜；研究链上数据和宏观情绪相结合，构建自己的交易思维。保持谨慎乐观！| 近3亿用户的共同选择就在币安 | #OKX web3入口一个就够",
      features: ["数据分析", "市场洞察", "策略制定", "风险管理"],
      rating: 4.7,
      usageCount: 14321
    },
    // 宝藏博主
    {
      id: 16,
      name: "憨巴龙王",
      creator: "@dotyyds1234",
      avatar: <img src="https://pbs.twimg.com/profile_images/1701493248269881344/uXTOJ_0J_normal.jpg" alt="Guilin_Chen_" style={{ width: '2em', height: '2em', objectFit: 'cover', borderRadius: '20%' }} />,
      avatarColor: "#13C2C2",
      type: "content",
      tags: ["宝藏博主", "市场分析", "投资策略"],
      description: "瞎几把喊单的，找你接盘的，别买，骂我看不见。",
      features: ["市场解读", "投资策略", "风险提示", "机会发现"],
      rating: 4.8,
      usageCount: 15432
    },
    {
      id: 17,
      name: "风风无向",
      creator: "@0x0xFeng",
      avatar: <img src="https://pbs.twimg.com/profile_images/1895903886054936576/JuxCtdWI_normal.jpg" alt="Guilin_Chen_" style={{ width: '2em', height: '2em', objectFit: 'cover', borderRadius: '20%' }} />,
      avatarColor: "#F5222D",
      type: "content",
      tags: ["宝藏博主", "市场分析", "投资思路"],
      description: "梭哈是一种智慧. All in Crypto .Solana Max! 📈敬告: 本人言论均为网上冲浪，不构成任何投资建议。 币圈最强空投撸毛工具 @Wave_Tool 创始人.欢迎使用",
      features: ["市场洞察", "投资思路", "风险管理", "策略分享"],
      rating: 4.7,
      usageCount: 13567
    },
    // 交易所官方
    {
      id: 18,
      name: "sisi",
      creator: "@sisibinance",
      avatar: <img src="https://pbs.twimg.com/profile_images/1902417399426772995/ozn-P54e_normal.jpg" alt="Guilin_Chen_" style={{ width: '2em', height: '2em', objectFit: 'cover', borderRadius: '20%' }} />,
      avatarColor: "#1890FF",
      type: "exchange",
      tags: ["交易所官方", "市场动态", "政策解读"],
      description: "永远保持进攻的角宿🌟Telegram：ElsieSisi / WeChat：binancesisi2 （其它联系方式均是冒充，请勿相信）",
      features: ["政策解读", "市场动态", "交易所资讯", "行业趋势"],
      rating: 4.9,
      usageCount: 19876
    },
    {
      id: 19,
      name: "九妹",
      creator: "@Cryptosis_0KX",
      avatar: <img src="https://pbs.twimg.com/profile_images/1453864539720601606/rEHaWNk1_normal.jpg" alt="Guilin_Chen_" style={{ width: '2em', height: '2em', objectFit: 'cover', borderRadius: '20%' }} />,
      avatarColor: "#722ED1",
      type: "exchange",
      tags: ["交易所官方", "市场资讯", "政策动态"],
      description: "GMGN 非著名 E卫兵 非著名 $WIFI 卫兵 做多中国特种兵🇨🇳🇨🇳🇨🇳🇨🇳🇨🇳🇨🇳",
      features: ["市场资讯", "政策解读", "平台动态", "行业趋势"],
      rating: 4.8,
      usageCount: 16543
    },
    // 情绪价值
    {
      id: 20,
      name: "奶子哥",
      creator: "@cryptocishanjia",
      avatar: <img src="https://pbs.twimg.com/profile_images/1464518155020083204/OW28mCLv_400x400.jpg" alt="Guilin_Chen_" style={{ width: '2em', height: '2em', objectFit: 'cover', borderRadius: '20%' }} />,
      avatarColor: "#52C41A",
      type: "sentiment",
      tags: ["情绪价值", "市场情绪", "趋势分析"],
      description: "话痨博主，傻逼韭菜，会点java，不构成投资建议。",
      features: ["情绪分析", "市场趋势", "投资建议", "风险提示"],
      rating: 4.7,
      usageCount: 13567
    },
    {
      id: 21,
      name: "凉兮大元帅 (白虎)",
      creator: "@liangxihuai",
      avatar: <img src="https://pbs.twimg.com/profile_images/740054216/hexies_normal.jpg" alt="Guilin_Chen_" style={{ width: '2em', height: '2em', objectFit: 'cover', borderRadius: '20%' }} />,
      avatarColor: "#F5222D",
      type: "sentiment",
      tags: ["情绪价值", "市场分析", "投资策略"],
      description: "meme degen Founder of 985 community Founder of @doobroCN 不要对短期的收益过于敏感，不要对长期的收益缺乏想象。",
      features: ["情绪研究", "市场分析", "策略制定", "风险管理"],
      rating: 4.8,
      usageCount: 15432
    },
    // 宏观分析
    {
      id: 22,
      name: "Phyrex",
      creator: "@Phyrex_Ni",
      avatar: <img src="https://pbs.twimg.com/profile_images/1596374669824696320/MGne6qLH_normal.jpg" alt="Guilin_Chen_" style={{ width: '2em', height: '2em', objectFit: 'cover', borderRadius: '20%' }} />,
      avatarColor: "#1DA1F2",
      type: "macro",
      tags: ["宏观分析", "市场研究", "趋势预测"],
      description: "没有群也不收费，所有分析回答均不构成投资建议 近3亿用户的共同选择就在Binance Crypto入口OKX就够了 Solar SG发起人",
      features: ["宏观分析", "市场研究", "趋势预测", "策略制定"],
      rating: 4.8,
      usageCount: 16789
    },
    {
      id: 23,
      name: "qinbafrank",
      creator: "@qinbafrank",
      avatar: <img src="https://pbs.twimg.com/profile_images/1453864539720601606/rEHaWNk1_normal.jpg" alt="Guilin_Chen_" style={{ width: '2em', height: '2em', objectFit: 'cover', borderRadius: '20%' }} />,
      avatarColor: "#722ED1",
      type: "macro",
      tags: ["宏观分析", "市场研究", "投资策略"],
      description: "Investor in Crypto、TMT、AI ，跟踪最前沿科技趋势、野生宏观政经观察、研究全球资本流动性、周期趋势投资。记录个人学习和思考，经常出错常态掉坑爬坑。Runner🏃 Crypto入口OKX就够了",
      features: ["宏观研究", "市场分析", "策略制定", "风险管理"],
      rating: 4.7,
      usageCount: 14567
    },
    // 链上 alpha 继续添加
    {
      id: 24,
      name: "0xSun",
      creator: "@0xSunNFT",
      avatar: <img src="https://pbs.twimg.com/profile_images/1820705442735296512/ej9BX01I_normal.png" alt="Guilin_Chen_" style={{ width: '2em', height: '2em', objectFit: 'cover', borderRadius: '20%' }} />,
      avatarColor: "#1890FF",
      type: "onchain",
      tags: ["链上alpha", "NFT分析", "市场洞察"],
      description: "Founder of 0xSun group - 狙击/刮刀Bot - OKX Web3钱包现已支持100+公链",
      features: ["NFT分析", "市场洞察", "投资建议", "风险管理"],
      rating: 4.8,
      usageCount: 14789
    },
    {
      id: 25,
      name: "冷静冷静再冷静",
      creator: "@hexies",
      avatar: <img src="https://pbs.twimg.com/profile_images/740054216/hexies_normal.jpg" alt="Guilin_Chen_" style={{ width: '2em', height: '2em', objectFit: 'cover', borderRadius: '20%' }} />,
      avatarColor: "#722ED1",
      type: "onchain",
      tags: ["链上alpha", "理性分析", "风险控制"],
      description: "专注，耐心，知行合一。私聊我前，把置顶推文点开下拉看，会节约大家时间。",
      features: ["风险分析", "市场研判", "投资策略", "资金管理"],
      rating: 4.7,
      usageCount: 13567
    },
    {
      id: 26,
      name: "0x易经",
      creator: "@DekuKing1",
      avatar: <img src="https://pbs.twimg.com/profile_images/1813228124894863362/Z3rJrZCq_normal.jpg" alt="Guilin_Chen_" style={{ width: '2em', height: '2em', objectFit: 'cover', borderRadius: '20%' }} />,
      avatarColor: "#13C2C2",
      type: "onchain",
      tags: ["链上alpha", "技术分析", "市场预测"],
      description: "GMGN 非著名 E卫兵 非著名 $WIFI 卫兵 做多中国特种兵🇨🇳🇨🇳🇨🇳🇨🇳🇨🇳🇨🇳",
      features: ["技术分析", "市场预测", "风险控制", "策略制定"],
      rating: 4.8,
      usageCount: 15234
    },
    {
      id: 27,
      name: "LaserCat397.eth",
      creator: "@BitCloutCat",
      avatar: <img src="https://pbs.twimg.com/profile_images/1848651115405185024/lhJUEX6s_normal.jpg" alt="Guilin_Chen_" style={{ width: '2em', height: '2em', objectFit: 'cover', borderRadius: '20%' }} />,
      avatarColor: "#F5222D",
      type: "onchain",
      tags: ["链上alpha", "项目分析", "投资机会"],
      description: "专注，耐心，知行合一。私聊我前，把置顶推文点开下拉看，会节约大家时间。",
      features: ["项目研究", "机会发现", "风险评估", "策略建议"],
      rating: 4.7,
      usageCount: 13890
    },
    {
      id: 28,
      name: "0xuezhang|985.eth",
      creator: "@Unipioneer",
      avatar: <img src="https://pbs.twimg.com/profile_images/1701007591583916032/VxdX5iTX_normal.png" alt="Guilin_Chen_" style={{ width: '2em', height: '2em', objectFit: 'cover', borderRadius: '20%' }} />,
      avatarColor: "#52C41A",
      type: "onchain",
      tags: ["链上alpha", "DeFi研究", "项目分析"],
      description: "make something.",
      features: ["DeFi分析", "项目评估", "收益优化", "风险控制"],
      rating: 4.8,
      usageCount: 14567
    },
    // 交易大神继续添加
    {
      id: 29,
      name: "比特肥 || Bitfatty",
      creator: "@Bitfatty",
      avatar: <img src="https://pbs.twimg.com/profile_images/1525287875876913152/ToCxWImL_normal.jpg" alt="Guilin_Chen_" style={{ width: '2em', height: '2em', objectFit: 'cover', borderRadius: '20%' }} />,
      avatarColor: "#1DA1F2",
      type: "trading",
      tags: ["交易大神", "技术分析", "市场洞察"],
      description: "四届 #binance 合约锦标赛 霸榜 TEAM 加入我们",
      features: ["技术分析", "趋势判断", "交易策略", "风险管理"],
      rating: 4.8,
      usageCount: 16543
    },
    {
      id: 30,
      name: "0x牛牛 / DrcDAO",
      creator: "@DrcDAO",
      avatar: <img src="https://pbs.twimg.com/profile_images/1760325630028296193/VYwAOvNF_normal.jpg" alt="Guilin_Chen_" style={{ width: '2em', height: '2em', objectFit: 'cover', borderRadius: '20%' }} />,
      avatarColor: "#722ED1",
      type: "trading",
      tags: ["交易大神", "DAO治理", "投资策略"],
      description: "DigitalRenaissanceCapital",
      features: ["DAO分析", "投资策略", "治理参与", "风险控制"],
      rating: 4.7,
      usageCount: 14321
    },
    {
      id: 31,
      name: "逍遥XTony",
      creator: "@xtony1314",
      avatar: <img src="https://pbs.twimg.com/profile_images/1897681346174283776/ABKW5uor_400x400.jpg" alt="Guilin_Chen_" style={{ width: '2em', height: '2em', objectFit: 'cover', borderRadius: '20%' }} />,
      avatarColor: "#13C2C2",
      type: "trading",
      tags: ["交易大神", "市场分析", "交易策略"],
      description: "独立交易员｜托学创始人｜网络作家｜人类观察家，历史成绩：实盘 5W➡️5000W ，2.5年1000倍收益率 交易著作 《法师Tony交易语录》|  网络著作《重生之我是托尼》",
      features: ["市场分析", "交易策略", "风险管理", "仓位控制"],
      rating: 4.8,
      usageCount: 15678
    },
    {
      id: 32,
      name: "K线教主",
      creator: "@Paris13Jeanne",
      avatar: <img src="https://pbs.twimg.com/profile_images/1832061353864904704/p8Nc4qqK_normal.jpg" alt="Guilin_Chen_" style={{ width: '2em', height: '2em', objectFit: 'cover', borderRadius: '20%' }} />,
      avatarColor: "#F5222D",
      type: "trading",
      tags: ["交易大神", "技术分析", "K线研究"],
      description: "YZi Labs invests in ventures at every stage, prioritizing those with solid fundamentals in Web3, AI, and biotech.",
      features: ["K线分析", "形态识别", "交易信号", "风险提示"],
      rating: 4.9,
      usageCount: 17654
    },
    {
      id: 33,
      name: "带带带比特",
      creator: "@daidaibtc",
      avatar: <img src="https://pbs.twimg.com/profile_images/1753543065292431360/j4831bJN_normal.jpg" alt="Guilin_Chen_" style={{ width: '2em', height: '2em', objectFit: 'cover', borderRadius: '20%' }} />,
      avatarColor: "#1890FF",
      type: "trading",
      tags: ["交易大神", "市场分析", "交易策略"],
      description: "个人社群在置顶，感谢支持｜没有人能在赌场里一直赢，做好资金管理，一定一定一定不要返贫｜交易就用币安",
      features: ["市场分析", "交易策略", "风险管理", "收益优化"],
      rating: 4.8,
      usageCount: 15432
    },
    {
      id: 34,
      name: "余三水",
      creator: "@YSI_crypto",
      avatar: <img src="https://pbs.twimg.com/profile_images/1707633073142333440/wA6_77Dw_normal.jpg" alt="Guilin_Chen_" style={{ width: '2em', height: '2em', objectFit: 'cover', borderRadius: '20%' }} />,
      avatarColor: "#52C41A",
      type: "trading",
      tags: ["交易大神", "投资策略", "风险管理"],
      description: "没有任何人设丨 #Binance 丨 #Bitget 丨 #全球TOP2 OKX手续费减免20%",
      features: ["投资策略", "风险控制", "资金管理", "市场分析"],
      rating: 4.7,
      usageCount: 14567
    },
    {
      id: 35,
      name: "陈桂林",
      creator: "@Guilin_Chen_",
      avatar: <img src="https://pbs.twimg.com/profile_images/1828916221057871873/hFY-eURZ_normal.jpg" alt="Guilin_Chen_" style={{ width: '2em', height: '2em', objectFit: 'cover', borderRadius: '20%' }} />,
      avatarColor: "#722ED1",
      type: "trading",
      tags: ["交易大神", "市场研究", "投资策略"],
      description: "专注，耐心，知行合一。私聊我前，把置顶推文点开下拉看，会节约大家时间。",
      features: ["市场研究", "投资方法", "策略制定", "风险管理"],
      rating: 4.8,
      usageCount: 15789
    },
    // 撸毛大神继续添加
    {
      id: 36,
      name: "币圈女菩萨",
      creator: "@xinctnnq",
      avatar: <img src="https://pbs.twimg.com/profile_images/1826078905964670976/V9SdAqNa_normal.jpg" alt="Guilin_Chen_" style={{ width: '2em', height: '2em', objectFit: 'cover', borderRadius: '20%' }} />,
      avatarColor: "#13C2C2",
      type: "airdrop",
      tags: ["撸毛大神", "空投情报", "项目分析"],
      description: "",
      features: ["空投分析", "项目筛选", "操作指南", "风险提示"],
      rating: 4.8,
      usageCount: 15432
    },
    {
      id: 37,
      name: "冰蛙",
      creator: "@Ice_frog66666",
      avatar: <img src="https://pbs.twimg.com/profile_images/1538063339917430784/-XWBxDFr_normal.jpg" alt="Guilin_Chen_" style={{ width: '2em', height: '2em', objectFit: 'cover', borderRadius: '20%' }} />,
      avatarColor: "#F5222D",
      type: "airdrop",
      tags: ["撸毛大神", "空投攻略", "收益优化"],
      description: "听说我是币圈著名反指KOL。所以我的推特均不构成投资建议，当然你可以反着开。",
      features: ["空投攻略", "收益优化", "风险控制", "操作指导"],
      rating: 4.7,
      usageCount: 13678
    },
    {
      id: 38,
      name: "何币",
      creator: "@hebi555",
      avatar: <img src="https://pbs.twimg.com/profile_images/1677888294661791748/GzBJhJcb_normal.jpg" alt="Guilin_Chen_" style={{ width: '2em', height: '2em', objectFit: 'cover', borderRadius: '20%' }} />,
      avatarColor: "#1890FF",
      type: "airdrop",
      tags: ["撸毛大神", "项目分析", "空投情报"],
      description: "注意：此帐户中提到的任何内容都不是投资建议 本推特提到的任何项目都不要信，不要看，不要点，更不要买 链上必备交易平台 新人首选OKX，安全简单",
      features: ["项目分析", "空投情报", "收益评估", "风险提示"],
      rating: 4.8,
      usageCount: 14567
    },
    {
      id: 39,
      name: "炼金叔叔",
      creator: "@AirdropAlchemis",
      avatar: <img src="https://pbs.twimg.com/profile_images/1821804297723764736/iKiwTO9z_normal.jpg" alt="Guilin_Chen_" style={{ width: '2em', height: '2em', objectFit: 'cover', borderRadius: '20%' }} />,
      avatarColor: "#52C41A",
      type: "airdrop",
      tags: ["撸毛大神", "空投研究", "收益分析"],
      description: "Solana is a high performance blockchain that powers scalable, low-cost crypto applications ◎ X by @SolanaFndn",
      features: ["空投研究", "收益分析", "策略优化", "风险管理"],
      rating: 4.7,
      usageCount: 13890
    },
    // 链上数据继续添加
    {
      id: 40,
      name: "貝格先生",
      creator: "@market_beggar",
      avatar: <img src="https://pbs.twimg.com/profile_images/1863831822465118208/enqnhGlW_normal.jpg" alt="Guilin_Chen_" style={{ width: '2em', height: '2em', objectFit: 'cover', borderRadius: '20%' }} />,
      avatarColor: "#722ED1",
      type: "data",
      tags: ["链上数据", "市场分析", "数据挖掘"],
      description: "Narrative Trader｜On-Chain Analyst｜Index Investor  📰鏈上週報、教學、分析 以交易為生；已清倉逃頂；無群組 註冊 #OKX 享 20% 手續費折扣 商務 TG：@colin_the_beggar",
      features: ["数据分析", "市场研究", "趋势预测", "机会发现"],
      rating: 4.8,
      usageCount: 15678
    },
    {
      id: 41,
      name: "Ai姨",
      creator: "@ai_9684xtpa",
      avatar: <img src="https://pbs.twimg.com/profile_images/1599605643744116736/Pstsnsb6_normal.jpg" alt="Guilin_Chen_" style={{ width: '2em', height: '2em', objectFit: 'cover', borderRadius: '20%' }} />,
      avatarColor: "#13C2C2",
      type: "data",
      tags: ["链上数据", "AI分析", "市场预测"],
      description: "#crypto is the antidote of APTX4869 💊 链上小说家，关心天气、食物、还有故事。 DYOR #DeFi #Meme #NFT #BTC #Binance Bybit",
      features: ["AI分析", "数据挖掘", "趋势预测", "风险评估"],
      rating: 4.7,
      usageCount: 14321
    },
    {
      id: 42,
      name: "BITWU.ETH",
      creator: "@BTW0205",
      avatar: <img src="https://pbs.twimg.com/profile_images/1826078905964670976/V9SdAqNa_normal.jpg" alt="Guilin_Chen_" style={{ width: '2em', height: '2em', objectFit: 'cover', borderRadius: '20%' }} />,
      avatarColor: "#F5222D",
      type: "data",
      tags: ["链上数据", "市场分析", "趋势研究"],
      description: "$BTC $ETH holder and Crypto investor. Hunting opportunities No investment advice",
      features: ["数据分析", "趋势研究", "机会发现", "风险控制"],
      rating: 4.8,
      usageCount: 15432
    },
    {
      id: 43,
      name: "余烬",
      creator: "@EmberCN",
      avatar: <img src="https://pbs.twimg.com/profile_images/1538063339917430784/-XWBxDFr_normal.jpg" alt="Guilin_Chen_" style={{ width: '2em', height: '2em', objectFit: 'cover', borderRadius: '20%' }} />,
      avatarColor: "#1890FF",
      type: "data",
      tags: ["链上数据", "市场研究", "数据分析"],
      description: "分享链上数据 数据搬运工+非客观分析 偶尔发广告 所有言论都没有投资建议",
      features: ["数据研究", "市场分析", "趋势预测", "风险评估"],
      rating: 4.7,
      usageCount: 13567
    },
    // 宝藏博主继续添加
    {
      id: 44,
      name: "梭教授说",
      creator: "@hellosuoha",
      avatar: <img src="https://pbs.twimg.com/profile_images/1895723975612710912/xduPiJlK_normal.jpg" alt="Guilin_Chen_" style={{ width: '2em', height: '2em', objectFit: 'cover', borderRadius: '20%' }} />,
      avatarColor: "#52C41A",
      type: "content",
      tags: ["宝藏博主", "市场教育", "投资指导"],
      description: "LOVE & SHARE & PEACE #LSPDAO #CRYPTO #WEB3 #NFT #GAMEFI #DEFI #META #AI #BRC20 #DEPIN",
      features: ["市场教育", "投资指导", "风险教育", "策略分享"],
      rating: 4.8,
      usageCount: 15789
    },
    {
      id: 45,
      name: "zunzun",
      creator: "@zunzunv",
      avatar: <img src="https://pbs.twimg.com/profile_images/1686974399495520256/wb8y-tnp_normal.jpg" alt="Guilin_Chen_" style={{ width: '2em', height: '2em', objectFit: 'cover', borderRadius: '20%' }} />,
      avatarColor: "#722ED1",
      type: "content",
      tags: ["宝藏博主", "市场分析", "投资思路"],
      description: "zn",
      features: ["市场分析", "投资思路", "风险管理", "策略分享"],
      rating: 4.7,
      usageCount: 13890
    },
    {
      id: 46,
      name: "Goku很Cool",
      creator: "@gokunocool",
      avatar: <img src="https://pbs.twimg.com/profile_images/1855210295943385089/FBKl8RSQ_normal.jpg" alt="Guilin_Chen_" style={{ width: '2em', height: '2em', objectFit: 'cover', borderRadius: '20%' }} />,
      avatarColor: "#13C2C2",
      type: "content",
      tags: ["宝藏博主", "市场见解", "投资策略"],
      description: "Play poker with friends using crypto Partner of $NGNF Club 70% revenue for $NGNF repurchases and burns",
      features: ["市场分析", "投资策略", "风险控制", "机会发现"],
      rating: 4.8,
      usageCount: 14567
    },
    {
      id: 47,
      name: "很大很大的橙子",
      creator: "@0xVeryBigOrange",
      avatar: <img src="https://pbs.twimg.com/profile_images/1406527898308136961/jRLnNYIr_normal.jpg" alt="Guilin_Chen_" style={{ width: '2em', height: '2em', objectFit: 'cover', borderRadius: '20%' }} />,
      avatarColor: "#F5222D",
      type: "content",
      tags: ["宝藏博主", "市场分析", "投资机会"],
      description: "听说我是币圈著名反指KOL。 所以我的推特均不构成投资建议，当然你可以反着开。",
      features: ["市场分析", "机会发现", "风险提示", "策略分享"],
      rating: 4.7,
      usageCount: 13456
    },
    {
      id: 48,
      name: "匿名博士",
      creator: "@Chris_Defi",
      avatar: <img src="https://pbs.twimg.com/profile_images/1559594596111228928/PIS0hee7_normal.jpg" alt="Guilin_Chen_" style={{ width: '2em', height: '2em', objectFit: 'cover', borderRadius: '20%' }} />,
      avatarColor: "#1890FF",
      type: "content",
      tags: ["宝藏博主", "DeFi研究", "市场分析"],
      description: "Web3｜玄学｜占卜｜周易｜加密占卜｜玄学金融｜金融占星｜宏观交易｜灵魂画师｜K线编剧｜擅长马后炮",
      features: ["DeFi分析", "市场研究", "投资策略", "风险管理"],
      rating: 4.8,
      usageCount: 15234
    },
    // 情绪价值继续添加
    {
      id: 49,
      name: "低等生物",
      creator: "@didengshengwu",
      avatar: <img src="https://pbs.twimg.com/profile_images/1735957716340965376/P8LprWrm_normal.jpg" alt="Guilin_Chen_" style={{ width: '2em', height: '2em', objectFit: 'cover', borderRadius: '20%' }} />,
      avatarColor: "#52C41A",
      type: "sentiment",
      tags: ["情绪价值", "市场情绪", "投资心理"],
      description: "喜欢心理学和炒币，关注我一起刨析人性真像 ！ #OKX 是全球领先的 #Crypto 交易平台，提供 #btc #eth 等多种交易，帮您安全投资数字资产",
      features: ["情绪分析", "心理研究", "市场预测", "风险提示"],
      rating: 4.7,
      usageCount: 13789
    },
    {
      id: 50,
      name: "谢家印",
      creator: "@xiejiayinBitget",
      avatar: <img src="https://pbs.twimg.com/profile_images/1735423234382913536/PALwhULD_normal.jpg" alt="Guilin_Chen_" style={{ width: '2em', height: '2em', objectFit: 'cover', borderRadius: '20%' }} />,
      avatarColor: "#722ED1",
      type: "exchange",
      tags: ["交易所官方", "市场动态", "政策解读"],
      description: "⚽️ LALIGA EA SPORTS & LALIGA HYPERMOTION 🌍 #EarnItOnThePitch",
      features: ["市场资讯", "政策解读", "平台动态", "行业趋势"],
      rating: 4.8,
      usageCount: 15678
    }

].map(advisor => ({
  ...advisor,
  avatar: advisor.avatar && React.cloneElement(advisor.avatar, {
    style: { ...advisor.avatar.props.style, width: '2em', height: '2em' }
  })
})); 