import React, { useEffect, useRef } from 'react';
import styled from '@emotion/styled';

const BackgroundCanvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.1;
`;

const TechBackground = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let animationFrameId;
    let grid = [];
    let gridSize = 30;

    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      initGrid();
    };

    const initGrid = () => {
      grid = [];
      const cols = Math.ceil(canvas.width / gridSize);
      const rows = Math.ceil(canvas.height / gridSize);

      for (let i = 0; i < cols; i++) {
        for (let j = 0; j < rows; j++) {
          grid.push({
            x: i * gridSize,
            y: j * gridSize,
            size: Math.random() * 2 + 1,
            active: Math.random() > 0.7,
            pulseSpeed: Math.random() * 0.02 + 0.01,
            pulseOffset: Math.random() * Math.PI * 2
          });
        }
      }
    };

    const drawGrid = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      
      // 绘制网格点
      grid.forEach(point => {
        if (point.active) {
          const pulse = Math.sin(Date.now() * point.pulseSpeed + point.pulseOffset) * 0.5 + 0.5;
          ctx.fillStyle = `rgba(49, 92, 236, ${0.3 + pulse * 0.7})`;
          ctx.beginPath();
          ctx.arc(point.x, point.y, point.size * pulse, 0, Math.PI * 2);
          ctx.fill();
        } else {
          ctx.fillStyle = 'rgba(49, 92, 236, 0.2)';
          ctx.beginPath();
          ctx.arc(point.x, point.y, point.size / 2, 0, Math.PI * 2);
          ctx.fill();
        }
      });
      
      // 绘制连接线
      ctx.strokeStyle = 'rgba(49, 92, 236, 0.1)';
      ctx.lineWidth = 0.5;
      
      for (let i = 0; i < grid.length; i++) {
        const point = grid[i];
        if (!point.active) continue;
        
        // 寻找最近的几个活跃点
        const nearbyPoints = grid
          .filter(p => p.active && p !== point)
          .map(p => ({
            point: p,
            distance: Math.sqrt(Math.pow(p.x - point.x, 2) + Math.pow(p.y - point.y, 2))
          }))
          .filter(p => p.distance < gridSize * 4)
          .sort((a, b) => a.distance - b.distance)
          .slice(0, 3);
        
        // 绘制连接线
        nearbyPoints.forEach(({ point: p, distance }) => {
          const opacity = 1 - distance / (gridSize * 4);
          ctx.strokeStyle = `rgba(49, 92, 236, ${opacity * 0.2})`;
          ctx.beginPath();
          ctx.moveTo(point.x, point.y);
          ctx.lineTo(p.x, p.y);
          ctx.stroke();
        });
      }
      
      // 随机激活/停用一些点
      if (Math.random() > 0.95) {
        const randomIndex = Math.floor(Math.random() * grid.length);
        grid[randomIndex].active = !grid[randomIndex].active;
      }
      
      animationFrameId = window.requestAnimationFrame(drawGrid);
    };

    window.addEventListener('resize', resizeCanvas);
    resizeCanvas();
    drawGrid();

    return () => {
      window.cancelAnimationFrame(animationFrameId);
      window.removeEventListener('resize', resizeCanvas);
    };
  }, []);

  return <BackgroundCanvas ref={canvasRef} />;
};

export default TechBackground; 