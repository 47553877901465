export const theme = {
  colors: {
    primary: '#315cec',
    primaryLight: '#4a6eee',
    primaryDark: '#2a4fd0',
    background: '#fbfbff',
    surface: '#ffffff',
    border: '#e7e7ea',
    text: {
      primary: '#333333',
      secondary: '#666666',
      light: '#999999',
    },
    success: '#28a745',
    error: '#dc3545',
    warning: '#ffc107',
    info: '#17a2b8',
  },
  shadows: {
    small: '0 2px 4px rgba(0, 0, 0, 0.05)',
    medium: '0 4px 8px rgba(0, 0, 0, 0.1)',
    large: '0 8px 16px rgba(0, 0, 0, 0.15)',
  },
  borderRadius: {
    small: '4px',
    medium: '8px',
    large: '12px',
  },
  spacing: {
    xs: '4px',
    sm: '8px',
    md: '16px',
    lg: '24px',
    xl: '32px',
    xxl: '48px',
  },
  gradients: {
    primary: 'linear-gradient(45deg, #1677ff 0%, #0958d9 100%)',
    glow: 'radial-gradient(circle at 50% 50%, rgba(22, 119, 255, 0.1) 0%, rgba(22, 119, 255, 0) 100%)'
  }
}; 