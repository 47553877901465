import React, { useState } from 'react';
import { Row, Col, Card, Input, Select, Tag, Button, Avatar, Divider, Modal, message, Rate, Form, Radio } from 'antd';
import { SearchOutlined, FilterOutlined, RobotOutlined, TwitterOutlined, 
  LineChartOutlined, SendOutlined, BarChartOutlined, InfoCircleOutlined, StarFilled, TeamOutlined,
  WalletOutlined, SwapOutlined, SafetyCertificateOutlined, ThunderboltOutlined,
  FireOutlined, AlertOutlined, ClockCircleOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { theme } from '../styles/theme';
import Footer from '../components/Layout/Footer';
import { kolData } from '../data/kolData';

const { Option } = Select;
const { Group: RadioGroup } = Radio;

// 样式组件
const PageContainer = styled.div`
  padding: 24px;
  max-width: 1200px;
  margin: 0 auto;
`;

const PageHeader = styled.div`
  margin-bottom: 24px;
`;

const PageTitle = styled.h1`
  font-size: 28px;
  margin-bottom: 8px;
  color: ${theme.colors.text.primary};
`;

const PageDescription = styled.p`
  color: ${theme.colors.text.secondary};
  font-size: 16px;
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  flex-wrap: wrap;
  gap: 16px;
`;

const SearchInput = styled(Input)`
  max-width: 300px;
`;

const FilterGroup = styled.div`
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
`;

const AdvisorCard = styled(Card)`
  height: 100%;
  transition: all 0.3s;
  cursor: pointer;
  border-radius: 8px;
  overflow: hidden;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  }
`;

const AdvisorAvatar = styled(Avatar)`
  background-color: ${props => props.bgcolor || theme.colors.primary};
  margin-right: 12px;
  font-size: 1em;
  width: 1em;
  height: 1em;
  line-height: 1em;
`;

const AdvisorHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const AdvisorLogo = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 12px;
  background-color: ${props => props.bgcolor || theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  font-size: 24px;
  color: white;
`;

const AdvisorInfo = styled.div`
  flex: 1;
`;

const AdvisorName = styled.h3`
  margin: 0 0 4px 0;
  font-size: 16px;
  font-weight: 500;
  color: ${theme.colors.text.primary};
`;

const AdvisorCreator = styled.div`
  font-size: 14px;
  color: ${theme.colors.text.secondary};
  display: flex;
  align-items: center;
`;

const AdvisorTags = styled.div`
  margin: 12px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const AdvisorDescription = styled.p`
  color: ${theme.colors.text.secondary};
  margin: 12px 0;
  font-size: 14px;
  line-height: 1.6;
  flex: 1;
  height: 66px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const StatsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 12px 0;
  padding: 8px 0;
  border-top: 1px solid ${theme.colors.border};
`;

const StatItem = styled.div`
  display: flex;
  align-items: center;
  color: ${theme.colors.text.secondary};
  font-size: 14px;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
  padding-top: 12px;
  border-top: 1px solid ${theme.colors.border};
`;

const ModalContent = styled.div`
  padding: 20px 0;
`;

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

const ModalAvatar = styled(Avatar)`
  width: 48px;
  height: 48px;
  border-radius: 12px;
  background-color: ${props => props.bgcolor || theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  font-size: 24px;
  color: white;
`;

const ModalTitle = styled.h2`
  margin: 0;
  font-size: 24px;
`;

const ModalDescription = styled.p`
  margin: 16px 0;
  color: ${theme.colors.text.secondary};
  font-size: 16px;
  line-height: 1.6;
`;

const FeatureList = styled.ul`
  margin: 24px 0;
  padding-left: 20px;
`;

const FeatureItem = styled.li`
  margin-bottom: 12px;
  color: ${theme.colors.text.primary};
`;

const CopyrightNotice = styled.div`
  background-color: #f5f5f5;
  padding: 24px;
  margin-top: 40px;
  text-align: center;
  color: ${theme.colors.text.secondary};
  font-size: 14px;
  line-height: 1.8;
`;

const CardContent = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const FormSection = styled.div`
  margin-bottom: 24px;
`;

const FormTitle = styled.h3`
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 500;
  color: ${theme.colors.text.primary};
`;

const AdvisorPage = () => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const [selectedType, setSelectedType] = useState('all');
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedAdvisor, setSelectedAdvisor] = useState(null);
  const [advisors] = useState(kolData);
  const [authorizedAdvisors, setAuthorizedAdvisors] = useState([]);
  const [assistants] = useState(kolData);
  
  // 过滤助理
  const filteredAdvisors = advisors.filter(advisor => {
    const matchesSearch = 
      advisor.name.toLowerCase().includes(searchText.toLowerCase()) ||
      advisor.description.toLowerCase().includes(searchText.toLowerCase()) ||
      advisor.tags.some(tag => tag.toLowerCase().includes(searchText.toLowerCase()));
    
    const matchesType = selectedType === 'all' || advisor.type === selectedType;
    
    return matchesSearch && matchesType;
  });
  
  // 处理助理卡片点击
  const handleAdvisorClick = (advisor) => {
    setSelectedAdvisor(advisor);
    setModalVisible(true);
  };
  
  // 处理详情按钮点击
  const handleDetailClick = (e, advisorId) => {
    e.stopPropagation(); // 阻止事件冒泡，避免触发卡片点击
    const advisor = advisors.find(a => a.id === advisorId);
    setSelectedAdvisor(advisor);
    setModalVisible(true);
  };
  
  // 修改 handleChatClick 函数，添加登录检查
  const handleChatClick = (id) => {
    // 检查用户是否登录（这里假设未登录）
    const isLoggedIn = false; // 实际应用中应该从认证状态或 localStorage 中获取
    
    if (!isLoggedIn) {
      message.warning('请先登录后再使用 AI 助理');
      // 可以选择导航到登录页面
      // navigate('/login');
      return;
    }
    
    // 如果已登录，则导航到聊天页面
    navigate(`/advisor/chat/${id}`);
  };

  const handleAuthorize = (advisorId, mode, duration) => {
    // Implementation of handleAuthorize function
  };

  const handleCancelAuthorize = (e, advisorId) => {
    // Implementation of handleCancelAuthorize function
  };

  return (
    <>
      <PageContainer>
        <PageHeader>
          <PageTitle>AI 助理</PageTitle>
          <PageDescription>
            选择专业的 AI 助理，帮助您完成社交媒体监控、内容创作、市场分析等任务
          </PageDescription>
        </PageHeader>
        
        <FilterContainer>
          <SearchInput 
            placeholder="搜索助理..." 
            prefix={<SearchOutlined />} 
            value={searchText}
            onChange={e => setSearchText(e.target.value)}
          />
          
          <FilterGroup>
            <Select 
              defaultValue="all" 
              style={{ width: 150 }} 
              onChange={value => setSelectedType(value)}
              prefix={<FilterOutlined />}
            >
              <Option value="all">所有类型</Option>
              <Option value="onchain">链上alpha</Option>
              <Option value="trading">交易大神</Option>
              <Option value="airdrop">撸毛大神</Option>
              <Option value="data">链上数据</Option>
              <Option value="content">宝藏博主</Option>
              <Option value="exchange">交易所官方</Option>
              <Option value="sentiment">情绪价值</Option>
              <Option value="macro">宏观分析</Option>
            </Select>
          </FilterGroup>
        </FilterContainer>
        
        <Row gutter={[24, 24]}>
          {filteredAdvisors.map((advisor) => (
            <Col xs={24} sm={12} md={8} key={advisor.id}>
              <AdvisorCard
                onClick={() => handleAdvisorClick(advisor)}
                hoverable
              >
                <CardContent>
                  <AdvisorHeader>
                    <AdvisorLogo bgcolor={advisor.avatarColor}>
                      {advisor.avatar}
                    </AdvisorLogo>
                    <AdvisorInfo>
                      <AdvisorName>{advisor.name}</AdvisorName>
                      <AdvisorCreator>
                        <TeamOutlined style={{ marginRight: 4 }} />
                        {(advisor.creator || '未知')}
                      </AdvisorCreator>
                    </AdvisorInfo>
                  </AdvisorHeader>
                  <AdvisorTags>
                    {advisor.tags.map((tag, index) => (
                      <Tag key={index} color={advisor.avatarColor}>{tag}</Tag>
                    ))}
                  </AdvisorTags>
                  <AdvisorDescription>{advisor.description}</AdvisorDescription>
                  <StatsContainer>
                    <StatItem>
                      <StarFilled style={{ color: '#FFD700', marginRight: 4 }} />
                      {advisor.rating.toFixed(1)}分
                    </StatItem>
                    <StatItem>
                      <TeamOutlined style={{ marginRight: 4 }} />
                      {advisor.usageCount.toLocaleString()}次使用
                    </StatItem>
                  </StatsContainer>
                  <ButtonGroup>
                    <Button 
                      type="primary" 
                      onClick={(e) => handleDetailClick(e, advisor.id)}
                    >
                      查看详情
                    </Button>
                  </ButtonGroup>
                </CardContent>
              </AdvisorCard>
            </Col>
          ))}
        </Row>
        
        <div className="view-more-container" style={{ textAlign: 'center', marginTop: '20px' }}>
          <Button 
            type="primary" 
            onClick={() => {
              const token = localStorage.getItem('token');
              if (!token) {
                Modal.confirm({
                  title: '需要登录',
                  content: '请先登录后再查看完整列表',
                  okText: '去登录',
                  cancelText: '取消',
                  onOk: () => {
                    navigate('/login');
                  }
                });
                return;
              }
              // TODO: 实现查看更多功能
              message.info('更多 AI 助理正在加载中...');
            }}
          >
            查看更多 AI 助理
          </Button>
        </div>
        
        {/* 在详情弹窗中保留"开始对话"按钮 */}
        {selectedAdvisor && (
          <Modal
            visible={modalVisible}
            title={null}
            footer={null}
            onCancel={() => setModalVisible(false)}
            width={600}
            bodyStyle={{ padding: '24px' }}
          >
            <ModalContent>
              <ModalHeader>
                <ModalAvatar bgcolor={selectedAdvisor.avatarColor}>
                  {selectedAdvisor.avatar}
                </ModalAvatar>
                <div>
                  <ModalTitle>{selectedAdvisor.name}</ModalTitle>
                  <div style={{ display: 'flex', alignItems: 'center', marginTop: 8 }}>
                    <Rate disabled defaultValue={selectedAdvisor.rating ? Math.round(selectedAdvisor.rating) : 0} style={{ fontSize: 16 }} />
                    <span style={{ marginLeft: 8, color: theme.colors.text.secondary }}>
                      {selectedAdvisor.rating ? selectedAdvisor.rating.toFixed(1) : '0.0'}分 
                      ({selectedAdvisor.usageCount ? selectedAdvisor.usageCount.toLocaleString() : '0'}次使用)
                    </span>
                  </div>
                </div>
              </ModalHeader>
              
              <ModalDescription>
                {selectedAdvisor.description}
              </ModalDescription>
              
              <Divider />
              
              <h3>主要功能</h3>
              <FeatureList>
                {selectedAdvisor.features.map((feature, index) => (
                  <FeatureItem key={index}>{feature}</FeatureItem>
                ))}
              </FeatureList>
              
              <Divider />
              
              <Button 
                type="primary" 
                size="large" 
                block
                onClick={() => {
                  const token = localStorage.getItem('token');
                  if (!token) {
                    message.warning('请先登录后再开始对话');
                    setModalVisible(false);
                    navigate('/login');
                    return;
                  }
                  // TODO: 实现开始对话功能
                  message.info('正在跳转到对话页面...');
                }}
              >
                开始对话
              </Button>
            </ModalContent>
          </Modal>
        )}
      </PageContainer>
      <CopyrightNotice>
        <div style={{ maxWidth: 1200, margin: '0 auto' }}>
          <p>版权声明</p>
          <p>平台的所有数据，均是通过正常的渠道，免费或付费获取的数据，涉及KOL的推文、助理、LOGO等版权均属于原作者所有，</p>
          <p>后续产生的直接收入部分也均归原作者所有，待平台功能全部开发完毕后，助理的使用权和所有权将转交对应的KOL。</p>
          <p>如果您是KOL或者有好的想法，希望增加一个助理，或者需要下架自己的助理，请通过推特 <a href="https://x.com/dyoaa_lee" target="_blank" rel="noopener noreferrer" style={{ color: '#1DA1F2' }}>@我们</a> 联系我们。</p>
        </div>
      </CopyrightNotice>
      <Footer />
    </>
  );
};

export default AdvisorPage; 