import { Global, css } from '@emotion/react';
import { theme } from './theme';

export const GlobalStyles = () => (
  <Global
    styles={css`
      :root {
        --primary-color: ${theme.colors.primary};
        --primary-light: ${theme.colors.primaryLight};
        --primary-dark: ${theme.colors.primaryDark};
        --bg-color: ${theme.colors.background};
        --surface-color: ${theme.colors.surface};
        --border-color: ${theme.colors.border};
        --text-primary: ${theme.colors.text.primary};
        --text-secondary: ${theme.colors.text.secondary};
        --text-light: ${theme.colors.text.light};
        --success-color: ${theme.colors.success};
        --error-color: ${theme.colors.error};
        --warning-color: ${theme.colors.warning};
        --info-color: ${theme.colors.info};
        --shadow-small: ${theme.shadows.small};
        --shadow-medium: ${theme.shadows.medium};
        --shadow-large: ${theme.shadows.large};
        --border-radius-small: ${theme.borderRadius.small};
        --border-radius-medium: ${theme.borderRadius.medium};
        --border-radius-large: ${theme.borderRadius.large};
        --spacing-xs: ${theme.spacing.xs};
        --spacing-sm: ${theme.spacing.sm};
        --spacing-md: ${theme.spacing.md};
        --spacing-lg: ${theme.spacing.lg};
        --spacing-xl: ${theme.spacing.xl};
        --spacing-xxl: ${theme.spacing.xxl};
        --transition: all 0.3s ease;
        --white: #ffffff;
      }

      * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
      }

      body {
        font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        background-color: var(--bg-color);
        color: var(--text-primary);
        line-height: 1.6;
      }

      a {
        text-decoration: none;
        color: var(--primary-color);
      }

      img {
        max-width: 100%;
      }

      button {
        cursor: pointer;
      }

      // 科技风格的滚动条
      ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }

      ::-webkit-scrollbar-track {
        background: ${theme.colors.surface};
      }

      ::-webkit-scrollbar-thumb {
        background: ${theme.colors.primary};
        border-radius: 3px;
      }

      // Ant Design 暗色主题覆盖
      .ant-btn {
        border-radius: 4px;
      }

      .ant-card {
        background: ${theme.colors.surface};
        border-color: ${theme.colors.border};
      }
    `}
  />
); 